/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Site, EvChargers, BEsSystems } from "../models";
import {
  getOverrideProps,
  useDataStoreUpdateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { schema } from "../models/schema";
import { useEffect } from "react";
import {
  CheckboxField,
  Divider,
  Flex,
  Heading,
  Radio,
  SliderField,
  StepperField,
  SwitchField,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import ImportExport2 from "./ImportExport2";
import HeaderActive from "./HeaderActive";
import SideBar2 from "./SideBar2";
export default function Infoframe(props) {
  const { site, evChargers, contact, bEsSystems, overrides, ...rest } = props;
  const [
    sliderFieldThreeFourNineFourThreeThreeSevenTwoValue,
    setSliderFieldThreeFourNineFourThreeThreeSevenTwoValue,
  ] = useStateMutationAction("50");
  const [
    sliderFieldThreeFourNineFourThreeThreeEightTwoValue,
    setSliderFieldThreeFourNineFourThreeThreeEightTwoValue,
  ] = useStateMutationAction("50");
  const [
    stepperFieldFourThreeOneZeroThreeNineSevenThreeValue,
    setStepperFieldFourThreeOneZeroThreeNineSevenThreeValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldFourThreeOneTwoThreeNineNineTwoValue,
    setStepperFieldFourThreeOneTwoThreeNineNineTwoValue,
  ] = useStateMutationAction(undefined);
  const [
    sliderFieldThreeSixNineZeroThreeNineNineThreeThumbColor,
    setSliderFieldThreeSixNineZeroThreeNineNineThreeThumbColor,
  ] = useStateMutationAction(undefined);
  const [
    sliderFieldThreeSixNineZeroThreeNineNineFourThumbColor,
    setSliderFieldThreeSixNineZeroThreeNineNineFourThumbColor,
  ] = useStateMutationAction(undefined);
  const [
    radioThreeFourNineFourThreeFourZeroFourColor,
    setRadioThreeFourNineFourThreeFourZeroFourColor,
  ] = useStateMutationAction(undefined);
  const [
    sliderFieldThreeFourNineFourThreeThreeSevenTwoThumbColor,
    setSliderFieldThreeFourNineFourThreeThreeSevenTwoThumbColor,
  ] = useStateMutationAction(undefined);
  const [
    sliderFieldThreeFourNineFourThreeThreeEightTwoThumbColor,
    setSliderFieldThreeFourNineFourThreeThreeEightTwoThumbColor,
  ] = useStateMutationAction(undefined);
  const sliderFieldThreeSixNineZeroThreeNineNineThreeOnChange =
    useDataStoreUpdateAction({
      model: EvChargers,
      id: site?.id,
      fields: {},
      schema: schema,
    });
  const sliderFieldThreeSixNineZeroThreeNineNineThreeOnMouseEnter = () => {
    setSliderFieldThreeSixNineZeroThreeNineNineThreeThumbColor("green");
  };
  const sliderFieldThreeSixNineZeroThreeNineNineThreeOnMouseLeave = () => {
    setSliderFieldThreeSixNineZeroThreeNineNineThreeThumbColor("white");
  };
  const checkboxFieldThreeSixNineZeroFourZeroThreeTwoOnChange =
    useDataStoreUpdateAction({
      model: EvChargers,
      id: site?.id,
      fields: {},
      schema: schema,
    });
  const sliderFieldThreeSixNineZeroThreeNineNineFourOnMouseEnter = () => {
    setSliderFieldThreeSixNineZeroThreeNineNineFourThumbColor("green");
  };
  const sliderFieldThreeSixNineZeroThreeNineNineFourOnMouseLeave = () => {
    setSliderFieldThreeSixNineZeroThreeNineNineFourThumbColor("white");
  };
  const sliderFieldThreeSixNineZeroThreeNineNineFourOnChange =
    useDataStoreUpdateAction({
      model: EvChargers,
      id: site?.id,
      fields: {},
      schema: schema,
    });
  const switchFieldThreeEightNineTwoThreeSevenSevenOneOnClick =
    useDataStoreUpdateAction({
      model: EvChargers,
      id: evChargers?.id,
      fields: {},
      schema: schema,
    });
  const radioThreeFourNineFourThreeFourZeroFourOnMouseLeave = () => {
    setRadioThreeFourNineFourThreeFourZeroFourColor("default");
  };
  const radioThreeFourNineFourThreeFourZeroFourOnMouseOver = () => {
    setRadioThreeFourNineFourThreeFourZeroFourColor("green");
  };
  const radioThreeFourNineFourThreeFourZeroFourOnClick =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: { ClimateMid: "radio" },
      schema: schema,
    });
  const radioThreeFourNineFourThreeThreeNineEightOnClick =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: { ClimateMorn: "radio" },
      schema: schema,
    });
  const sliderFieldThreeFourNineFourThreeThreeSevenTwoOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        SaveVsRes: sliderFieldThreeFourNineFourThreeThreeSevenTwoValue,
      },
      schema: schema,
    });
  const sliderFieldThreeFourNineFourThreeThreeSevenTwoOnMouseOver = () => {
    setSliderFieldThreeFourNineFourThreeThreeSevenTwoThumbColor("green");
  };
  const sliderFieldThreeFourNineFourThreeThreeSevenTwoOnMouseLeave = () => {
    setSliderFieldThreeFourNineFourThreeThreeSevenTwoThumbColor("white");
  };
  const checkboxFieldFourFourSevenThreeFourZeroZeroSevenOnChange =
    useDataStoreUpdateAction({
      model: BEsSystems,
      id: bEsSystems?.id,
      fields: {},
      schema: schema,
    });
  const sliderFieldThreeFourNineFourThreeThreeEightTwoOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        ResveTrade: sliderFieldThreeFourNineFourThreeThreeEightTwoValue,
      },
      schema: schema,
    });
  const sliderFieldThreeFourNineFourThreeThreeEightTwoOnMouseOver = () => {
    setSliderFieldThreeFourNineFourThreeThreeEightTwoThumbColor("green");
  };
  const sliderFieldThreeFourNineFourThreeThreeEightTwoOnMouseLeave = () => {
    setSliderFieldThreeFourNineFourThreeThreeEightTwoThumbColor("White");
  };
  const frameFourThreeZeroOnClick = useDataStoreUpdateAction({
    model: Site,
    id: site?.id,
    fields: {},
    schema: schema,
  });
  const switchFieldThreeEightNineTwoThreeSevenSevenFiveOnClick =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: { Pool_On: "On" },
      schema: schema,
    });
  const stepperFieldFourThreeOneZeroThreeNineSevenThreeOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        PauseControl: stepperFieldFourThreeOneZeroThreeNineSevenThreeValue,
      },
      schema: schema,
    });
  const stepperFieldFourThreeOneTwoThreeNineNineTwoOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        MinPeriodPool: stepperFieldFourThreeOneTwoThreeNineNineTwoValue,
      },
      schema: schema,
    });
  useEffect(() => {
    if (
      sliderFieldThreeFourNineFourThreeThreeSevenTwoValue === undefined &&
      site !== undefined &&
      `${site?.SaveVsRes}${"%"}` !== undefined
    )
      setSliderFieldThreeFourNineFourThreeThreeSevenTwoValue(
        `${site?.SaveVsRes}${"%"}`
      );
  }, [site]);
  useEffect(() => {
    if (
      sliderFieldThreeFourNineFourThreeThreeEightTwoValue === undefined &&
      site !== undefined &&
      `${site?.ResveTrade}${"%"}` !== undefined
    )
      setSliderFieldThreeFourNineFourThreeThreeEightTwoValue(
        `${site?.ResveTrade}${"%"}`
      );
  }, [site]);
  useEffect(() => {
    if (
      stepperFieldFourThreeOneZeroThreeNineSevenThreeValue === undefined &&
      site !== undefined &&
      site?.PauseControl !== undefined
    )
      setStepperFieldFourThreeOneZeroThreeNineSevenThreeValue(
        site?.PauseControl
      );
  }, [site]);
  useEffect(() => {
    if (
      stepperFieldFourThreeOneTwoThreeNineNineTwoValue === undefined &&
      site !== undefined &&
      site?.MinPeriodPool !== undefined
    )
      setStepperFieldFourThreeOneTwoThreeNineNineTwoValue(site?.MinPeriodPool);
  }, [site]);
  return (
    <View
      width="100vw"
      height="1101px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(229,241,219,1)"
      {...getOverrideProps(overrides, "Infoframe")}
      {...rest}
    >
      <View
        width="1311px"
        height="163px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="791px"
        left="126px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "EVPref")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="1311px"
          height="123px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          top="40px"
          left="0px"
          padding="10px 58px 10px 10px"
          {...getOverrideProps(overrides, "Frame 42736903992")}
        >
          <SliderField
            height="47px"
            label="Prioritise until SoC"
            defaultValue="50"
            shrink="0"
            isDisabled={false}
            labelHidden={false}
            isValueHidden={false}
            size="large"
            max="100"
            min="10"
            step="10"
            value={`${evChargers?.BoostUntilSoC}${"%"}`}
            thumbColor={sliderFieldThreeSixNineZeroThreeNineNineThreeThumbColor}
            onChange={() => {
              sliderFieldThreeSixNineZeroThreeNineNineThreeOnChange();
            }}
            onMouseEnter={() => {
              sliderFieldThreeSixNineZeroThreeNineNineThreeOnMouseEnter();
            }}
            onMouseLeave={() => {
              sliderFieldThreeSixNineZeroThreeNineNineThreeOnMouseLeave();
            }}
            onChange={(value) =>
              setSliderFieldThreeSixNineZeroThreeNineNineThreeThumbColor(value)
            }
            {...getOverrideProps(overrides, "SliderField36903993")}
          ></SliderField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Only use solar "
            shrink="0"
            size="xx-large"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="start"
            fontSize="x-large"
            value={evChargers?.Only_Solar}
            onChange={() => {
              checkboxFieldThreeSixNineZeroFourZeroThreeTwoOnChange();
            }}
            {...getOverrideProps(overrides, "CheckboxField36904032")}
          ></CheckboxField>
          <SliderField
            height="47px"
            label="Boost until SoC"
            shrink="0"
            defaultValue="50"
            isDisabled={false}
            labelHidden={false}
            isValueHidden={false}
            size="large"
            max="90"
            min="0"
            step="10"
            value={`${evChargers?.BoostUntilSoC}${"%"}`}
            thumbColor={sliderFieldThreeSixNineZeroThreeNineNineFourThumbColor}
            onMouseEnter={() => {
              sliderFieldThreeSixNineZeroThreeNineNineFourOnMouseEnter();
            }}
            onMouseLeave={() => {
              sliderFieldThreeSixNineZeroThreeNineNineFourOnMouseLeave();
            }}
            onChange={() => {
              sliderFieldThreeSixNineZeroThreeNineNineFourOnChange();
            }}
            onChange={(value) =>
              setSliderFieldThreeSixNineZeroThreeNineNineFourThumbColor(value)
            }
            {...getOverrideProps(overrides, "SliderField36903994")}
          ></SliderField>
          <SwitchField
            width="unset"
            height="unset"
            label="Force Boost"
            shrink="0"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="start"
            value={evChargers?.Overide}
            onClick={() => {
              switchFieldThreeEightNineTwoThreeSevenSevenOneOnClick();
            }}
            {...getOverrideProps(overrides, "SwitchField38923771")}
          ></SwitchField>
        </Flex>
        <Heading
          width="unset"
          height="unset"
          position="absolute"
          top="0px"
          left="530px"
          level="3"
          exist={evChargers?.id == "null" ? "font" : "default"}
          children="EV  Preferences"
          {...getOverrideProps(overrides, "Heading36903995")}
        ></Heading>
      </View>
      <View
        width="1295px"
        height="249px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="386px"
        left="145px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Powpref")}
      >
        <Flex
          gap="194px"
          direction="row"
          width="1262px"
          height="98px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          top="158px"
          right="20px"
          padding="19px 80px 19px 0px"
          {...getOverrideProps(overrides, "Frame 428")}
        >
          <Heading
            width="unset"
            height="unset"
            gap="12px"
            padding="0px 12px 0px 12px"
            shrink="0"
            level="3"
            fontWeight="bold"
            children="Priority Heating/ Cooling period"
            {...getOverrideProps(overrides, "Heading34943394")}
          ></Heading>
          <Flex
            gap="40px"
            direction="row"
            width="458px"
            height="62px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 12px 0px 12px"
            radioGroup="HVAC"
            {...getOverrideProps(overrides, "Frame 429")}
          >
            <Radio
              width="unset"
              height="unset"
              gap="12px"
              shrink="0"
              size="large"
              defaultChecked={true}
              isDisabled={false}
              labelPosition="start"
              value={site?.ClimateMid}
              children="Midday"
              color={radioThreeFourNineFourThreeFourZeroFourColor}
              onMouseLeave={() => {
                radioThreeFourNineFourThreeFourZeroFourOnMouseLeave();
              }}
              onMouseOver={() => {
                radioThreeFourNineFourThreeFourZeroFourOnMouseOver();
              }}
              onClick={() => {
                radioThreeFourNineFourThreeFourZeroFourOnClick();
              }}
              {...getOverrideProps(overrides, "Radio34943404")}
            ></Radio>
            <Radio
              width="unset"
              height="unset"
              gap="12px"
              shrink="0"
              size="large"
              defaultChecked={true}
              isDisabled={false}
              labelPosition="start"
              children="Morning"
              onClick={() => {
                radioThreeFourNineFourThreeThreeNineEightOnClick();
              }}
              {...getOverrideProps(overrides, "Radio34943398")}
            ></Radio>
            <Radio
              width="154px"
              height="unset"
              gap="12px"
              justifyContent="flex-start"
              shrink="0"
              size="large"
              defaultChecked={true}
              isDisabled={false}
              labelPosition="start"
              children="Afternoon"
              {...getOverrideProps(overrides, "Radio34943410")}
            ></Radio>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="1272px"
          height="102px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          top="49px"
          left="3px"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Frame 42734943351")}
        >
          <SliderField
            height="47px"
            label="Use vs Revenue"
            shrink="0"
            isDisabled={false}
            labelHidden={false}
            isValueHidden={false}
            size="large"
            max="100"
            min="1"
            value={sliderFieldThreeFourNineFourThreeThreeSevenTwoValue}
            thumbColor={
              sliderFieldThreeFourNineFourThreeThreeSevenTwoThumbColor
            }
            onChange={() => {
              sliderFieldThreeFourNineFourThreeThreeSevenTwoOnChange();
            }}
            onMouseOver={() => {
              sliderFieldThreeFourNineFourThreeThreeSevenTwoOnMouseOver();
            }}
            onMouseLeave={() => {
              sliderFieldThreeFourNineFourThreeThreeSevenTwoOnMouseLeave();
            }}
            onChange={(value) =>
              setSliderFieldThreeFourNineFourThreeThreeSevenTwoValue(value)
            }
            onChange={(value) =>
              setSliderFieldThreeFourNineFourThreeThreeSevenTwoThumbColor(value)
            }
            {...getOverrideProps(overrides, "SliderField34943372")}
          ></SliderField>
          <Flex
            gap="0"
            direction="column"
            width="505px"
            height="83px"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="2px 12px 2px 12px"
            {...getOverrideProps(overrides, "Frame 490")}
          >
            <CheckboxField
              width="unset"
              height="unset"
              label="Prioritise Hot Water  "
              shrink="0"
              size="large"
              defaultChecked={true}
              isDisabled={false}
              labelPosition="start"
              {...getOverrideProps(overrides, "CheckboxField42863917")}
            ></CheckboxField>
            <CheckboxField
              width="unset"
              height="unset"
              label="Trade from Storage (not recommended)"
              shrink="0"
              size="large"
              defaultChecked={true}
              isDisabled={false}
              labelPosition="start"
              color={
                bEsSystems?.EsModel == "No Device Registd" ? "Grey" : "default"
              }
              fontStyle={
                bEsSystems?.EsModel == "No Device Registd"
                  ? "Italic"
                  : "default"
              }
              onChange={() => {
                checkboxFieldFourFourSevenThreeFourZeroZeroSevenOnChange();
              }}
              {...getOverrideProps(overrides, "CheckboxField44734007")}
            ></CheckboxField>
          </Flex>
          <SliderField
            height="unset"
            label="Surplus for trade"
            shrink="0"
            isDisabled={false}
            labelHidden={false}
            isValueHidden={false}
            size="large"
            max="100"
            min="0"
            step="10"
            value={sliderFieldThreeFourNineFourThreeThreeEightTwoValue}
            thumbColor={
              sliderFieldThreeFourNineFourThreeThreeEightTwoThumbColor
            }
            onChange={() => {
              sliderFieldThreeFourNineFourThreeThreeEightTwoOnChange();
            }}
            onMouseOver={() => {
              sliderFieldThreeFourNineFourThreeThreeEightTwoOnMouseOver();
            }}
            onMouseLeave={() => {
              sliderFieldThreeFourNineFourThreeThreeEightTwoOnMouseLeave();
            }}
            onChange={(value) =>
              setSliderFieldThreeFourNineFourThreeThreeEightTwoValue(value)
            }
            onChange={(value) =>
              setSliderFieldThreeFourNineFourThreeThreeEightTwoThumbColor(value)
            }
            {...getOverrideProps(overrides, "SliderField34943382")}
          ></SliderField>
        </Flex>
        <Heading
          width="unset"
          height="unset"
          position="absolute"
          top="0px"
          left="423px"
          level="3"
          fontWeight="bold"
          children="Surplus power preferences"
          {...getOverrideProps(overrides, "Heading34943348")}
        ></Heading>
      </View>
      <View
        width="100vw"
        height="142px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="635px"
        left="150px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Control")}
      >
        <Heading
          width="unset"
          height="unset"
          position="absolute"
          top="0px"
          left="573px"
          level="3"
          fontWeight="bold"
          children="Control"
          {...getOverrideProps(overrides, "Heading34952899")}
        ></Heading>
        <View
          width="1272px"
          height="113px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="40px"
          left="8px"
          padding="0px 0px 0px 0px"
          onClick={() => {
            frameFourThreeZeroOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 430")}
        >
          <SwitchField
            width="160px"
            height="unset"
            label=" Force pool on "
            position="absolute"
            top="39px"
            left="1060px"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="start"
            value={site?.Pool_On}
            onClick={() => {
              switchFieldThreeEightNineTwoThreeSevenSevenFiveOnClick();
            }}
            {...getOverrideProps(overrides, "SwitchField38923775")}
          ></SwitchField>
          <StepperField
            width="299px"
            height="unset"
            label="Pause automation for (Hrs)"
            position="absolute"
            top="4px"
            left="15px"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            value={stepperFieldFourThreeOneZeroThreeNineSevenThreeValue}
            onStepChange={() => {
              stepperFieldFourThreeOneZeroThreeNineSevenThreeOnChange();
            }}
            onStepChange={(value) =>
              setStepperFieldFourThreeOneZeroThreeNineSevenThreeValue(value)
            }
            {...getOverrideProps(overrides, "StepperField43103973")}
          ></StepperField>
          <StepperField
            height="unset"
            label="Minimum pool pump (hrs)"
            position="absolute"
            top="4px"
            left="500px"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            value={stepperFieldFourThreeOneTwoThreeNineNineTwoValue}
            onStepChange={() => {
              stepperFieldFourThreeOneTwoThreeNineNineTwoOnChange();
            }}
            onStepChange={(value) =>
              setStepperFieldFourThreeOneTwoThreeNineNineTwoValue(value)
            }
            {...getOverrideProps(overrides, "StepperField43123992")}
          ></StepperField>
        </View>
      </View>
      <View
        width="100vw"
        height="205px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="176px"
        left="145px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Detail")}
      >
        <Flex
          gap="0"
          direction="row"
          width="1275px"
          height="63px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          top="128px"
          left="0px"
          padding="0px 20px 0px 20px"
          {...getOverrideProps(overrides, "Frame 425")}
        >
          <TextField
            width="300px"
            height="unset"
            placeholder="Longitude"
            shrink="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            id={site?.id}
            value={site?.SLongitude}
            {...getOverrideProps(overrides, "TextField34943325")}
          ></TextField>
          <TextField
            width="300px"
            height="unset"
            placeholder="Latitude"
            shrink="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            id={site?.id}
            value={site?.SLatitude}
            {...getOverrideProps(overrides, "TextField34943326")}
          ></TextField>
          <TextField
            width="300px"
            height="unset"
            placeholder="Elevation"
            shrink="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            id={site?.id}
            value={`${site?.SElevation}${" m"}`}
            {...getOverrideProps(overrides, "TextField34943327")}
          ></TextField>
        </Flex>
        <Flex
          gap="166px"
          direction="row"
          width="1272px"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          top="60px"
          left="0px"
          padding="9px 20px 9px 20px"
          {...getOverrideProps(overrides, "Frame 424")}
        >
          <TextField
            width="300px"
            height="unset"
            placeholder="Site Name"
            shrink="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            id={site?.id}
            value={site?.SiteName}
            {...getOverrideProps(overrides, "TextField34943302")}
          ></TextField>
          <TextField
            width="300px"
            height="unset"
            placeholder="Street"
            shrink="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            id={site?.id}
            value={site?.SStreet}
            {...getOverrideProps(overrides, "TextField34943309")}
          ></TextField>
          <TextField
            width="300px"
            height="unset"
            placeholder="Suburb"
            shrink="0"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            id={site?.id}
            value={site?.SSuburb}
            {...getOverrideProps(overrides, "TextField34943316")}
          ></TextField>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          position="absolute"
          top="0px"
          left="547px"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Frame 423")}
        >
          <Heading
            width="unset"
            height="unset"
            shrink="0"
            level="3"
            fontWeight="bold"
            children="Site Details"
            {...getOverrideProps(overrides, "Heading34943298")}
          ></Heading>
        </Flex>
      </View>
      <ImportExport2
        width="100w"
        height="62px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="92px"
        left="337px"
        padding="0px 0px 0px 0px"
        property1="Default"
        {...getOverrideProps(overrides, "ImportExport2")}
      ></ImportExport2>
      <HeaderActive
        width="100vw"
        height="68px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="1px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(58,86,66,1)"
        {...getOverrideProps(overrides, "HeaderActive")}
      ></HeaderActive>
      <Divider
        width="1311px"
        height="1px"
        position="absolute"
        top="776px"
        left="126px"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider36904041")}
      ></Divider>
      <Divider
        width="100w"
        height="1px"
        position="absolute"
        top="624px"
        left="126px"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider34952902")}
      ></Divider>
      <Divider
        width="100w"
        position="absolute"
        top="383px"
        left="126px"
        size="large"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider34952892")}
      ></Divider>
      <Divider
        width="100w"
        height="1px"
        position="absolute"
        top="555px"
        left="73px"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider34952890")}
      ></Divider>
      <SideBar2
        width="81px"
        height="1034px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="68px"
        left="0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(58,86,66,1)"
        {...getOverrideProps(overrides, "SideBar2")}
      ></SideBar2>
    </View>
  );
}
