import './App.css';
import { Amplify, Auth, } from 'aws-amplify';
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import awsExports from './aws-exports';
import { useEffect,useState  } from 'react';
import { API,graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
  /*
These are custom built frames
  */
import Home from './ui-customs/Home';
import SelectSites from './ui-customs/SelectSites';
import CustomSignUp from './ui-customs/CustomSignUp';
import CustomSignIn from './ui-customs/CustomSignIn';
import ChangePassword from './ui-customs/ChangePassword'
/*
Standard components and frames from figma
*/
import {
  MyIcon, Frame404, Trading, Underconstruction, Infoframe, HomeFrame, Financial, SiteSelectionCollection, HeaderActive, SiteSelect2, SideBar2, Automation, Privacy, IOTDevice
} from './ui-components';
/*
Elements of collections
 */
import {
  Changepassword
} from './ui-components';
/*
Collections
 */
import {BESstatusCollection, InverterstatusCollection, IOTDevicestatusCollection
} from './ui-components';
import {ThemeProvider} from "@aws-amplify/ui-react";
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import studioTheme from './ui-components/studioTheme';

Amplify.configure(awsconfig);
Amplify.configure(awsExports);
/*

 */
function App() {
  const [contact, setContact] = useState({})
  const [site, setSite] = useState({})
  const [login, setLogin] = useState(true)

  async function getContact() {

    let usr = await Auth.currentUserInfo();
    console.log(usr);
    if (usr){
      let contacts = await API.graphql({...graphqlOperation(queries.listContacts, { filter: { CognitoID: { eq :usr.username }}}), authMode : "API_KEY" })
      console.log(contacts)
      let cont = contacts.data.listContacts.items[0];
      console.log(cont)
      if (cont && cont.LastViewediD ) {
        console.log("*******************************************")
        let ste = await API.graphql({...graphqlOperation(queries.getSite, { id: cont.LastViewediD}) , authMode : "API_KEY" })
        console.log(ste)
        setSite(ste.data.getSite);
      } 
      setContact(cont);   
    }
  }

  useEffect(() => {

    getContact()
    
  },[contact.id]);

  const { user } = useAuthenticator();
/*
Menus ?
 */

  if (user){
    return (
      <Authenticator>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<Home contacts = {contact} site={site} />} />
            <Route exact path='/HomeFrame' element={<Home contacts = {contact} site={site} />} />
            <Route exact path='/Automation' element={<Automation overrides ={{HeaderActive:{contacts : contact}}} />} />
            <Route exact path='/Infoframe' element={<Infoframe site={site}   overrides ={{HeaderActive:{contacts : contact}}} />} />
            <Route exact path='/Underconstruction' element={<Underconstruction  overrides ={{HeaderActive:{contacts : contact}}} />} />
            <Route exact path='/Trading' element={<Trading  />} overrides ={{HeaderActive:{contacts : contact}}} />
            <Route exact path='/SiteSelect' element={<SelectSites contacts = {contact} site={site} />} />
            <Route exact path='/Financial' element={<Financial  overrides ={{HeaderActive:{contacts : contact}}} />} />
            <Route exact path='/Privacy' element={<Privacy  overrides ={{HeaderActive:{contacts : contact}}} />} />
            <Route exact path='/PasswordResset' element={<ChangePassword />} />
            <Route path='*' element={<Frame404  overrides ={{HeaderActive:{contacts : contact}}} />} />
          </Routes>
        </div>
      </Router>
      </Authenticator>
    );
  }else{
    return(
    <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<CustomSignIn />} />
            <Route exact path='/signin' element={<CustomSignIn />} />
            <Route exact path='/signup' element={<CustomSignUp />} />
            <Route exact path='*' element={<CustomSignIn />} />
          </Routes>
        </div>
      </Router>
    );

    //if (login) return  <CustomSignIn />
    //else return <CustomSignUp />
  }
}

export default App;
