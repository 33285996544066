/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function SideBar2(props) {
  const { images, overrides, ...rest } = props;
  const [
    homeThreeFourNineSevenThreeZeroZeroEightColor,
    setHomeThreeFourNineSevenThreeZeroZeroEightColor,
  ] = useStateMutationAction("rgba(232,245,233,1)");
  const [automateColor, setAutomateColor] = useStateMutationAction(
    "rgba(232,245,233,1)"
  );
  const [siteInfoColor, setSiteInfoColor] = useStateMutationAction(
    "rgba(232,245,233,1)"
  );
  const [
    reportingThreeFourNineSevenThreeZeroTwoZeroColor,
    setReportingThreeFourNineSevenThreeZeroTwoZeroColor,
  ] = useStateMutationAction("rgba(232,245,233,1)");
  const [animationColor, setAnimationColor] = useStateMutationAction(
    "rgba(232,245,233,1)"
  );
  const [
    tradingThreeFourNineSevenThreeZeroTwoEightColor,
    setTradingThreeFourNineSevenThreeZeroTwoEightColor,
  ] = useStateMutationAction("rgba(232,245,233,1)");
  const [sitesColor, setSitesColor] = useStateMutationAction(
    "rgba(232,245,233,1)"
  );
  const [
    financialThreeFourNineSevenThreeZeroThreeSixColor,
    setFinancialThreeFourNineSevenThreeZeroThreeSixColor,
  ] = useStateMutationAction("rgba(232,245,233,1)");
  const [
    privacyFourSixFourEightFourEightTwoOneColor,
    setPrivacyFourSixFourEightFourEightTwoOneColor,
  ] = useStateMutationAction("rgba(232,245,233,1)");
  const [setPWColor, setSetPWColor] = useStateMutationAction(
    "rgba(232,245,233,1)"
  );
  const homeThreeFourNineSevenThreeZeroZeroEightOnClick = useNavigateAction({
    type: "url",
    url: "/HomeFrame",
  });
  const homeThreeFourNineSevenThreeZeroZeroEightOnMouseLeave = () => {
    setHomeThreeFourNineSevenThreeZeroZeroEightColor("rgba(232,245,233,1)");
  };
  const homeThreeFourNineSevenThreeZeroZeroEightOnMouseOver = () => {
    setHomeThreeFourNineSevenThreeZeroZeroEightColor("green");
  };
  const automateOnClick = useNavigateAction({
    type: "url",
    url: "/Automation",
  });
  const automateOnMouseLeave = () => {
    setAutomateColor("rgba(232,245,233,1)");
  };
  const automateOnMouseOver = () => {
    setAutomateColor("green");
  };
  const siteInfoOnClick = useNavigateAction({ type: "url", url: "/Infoframe" });
  const siteInfoOnMouseLeave = () => {
    setSiteInfoColor("rgba(232,245,233,1)");
  };
  const siteInfoOnMouseOver = () => {
    setSiteInfoColor("green");
  };
  const reportingThreeFourNineSevenThreeZeroTwoZeroOnClick = useNavigateAction({
    type: "url",
    url: "/Underconstruction",
  });
  const reportingThreeFourNineSevenThreeZeroTwoZeroOnMouseLeave = () => {
    setReportingThreeFourNineSevenThreeZeroTwoZeroColor("rgba(232,245,233,1)");
  };
  const reportingThreeFourNineSevenThreeZeroTwoZeroOnMouseOver = () => {
    setReportingThreeFourNineSevenThreeZeroTwoZeroColor("green");
  };
  const animationOnClick = useNavigateAction({
    type: "url",
    url: "/Underconstruction",
  });
  const animationOnMouseLeave = () => {
    setAnimationColor("rgba(232,245,233,1)");
  };
  const animationOnMouseOver = () => {
    setAnimationColor("green");
  };
  const tradingThreeFourNineSevenThreeZeroTwoEightOnClick = useNavigateAction({
    type: "url",
    url: "/Trading",
  });
  const tradingThreeFourNineSevenThreeZeroTwoEightOnMouseLeave = () => {
    setTradingThreeFourNineSevenThreeZeroTwoEightColor("rgba(232,245,233,1)");
  };
  const tradingThreeFourNineSevenThreeZeroTwoEightOnMouseOver = () => {
    setTradingThreeFourNineSevenThreeZeroTwoEightColor("green");
  };
  const sitesOnClick = useNavigateAction({ type: "url", url: "/SiteSelect" });
  const sitesOnMouseLeave = () => {
    setSitesColor("rgba(232,245,233,1)");
  };
  const sitesOnMouseOver = () => {
    setSitesColor("green");
  };
  const financialThreeFourNineSevenThreeZeroThreeSixOnClick = useNavigateAction(
    { type: "url", url: "/Financial" }
  );
  const financialThreeFourNineSevenThreeZeroThreeSixOnMouseLeave = () => {
    setFinancialThreeFourNineSevenThreeZeroThreeSixColor("rgba(232,245,233,1)");
  };
  const financialThreeFourNineSevenThreeZeroThreeSixOnMouseOver = () => {
    setFinancialThreeFourNineSevenThreeZeroThreeSixColor("green");
  };
  const privacyFourSixFourEightFourEightTwoOneOnClick = useNavigateAction({
    type: "url",
    url: "/Privacy",
  });
  const privacyFourSixFourEightFourEightTwoOneOnMouseLeave = () => {
    setPrivacyFourSixFourEightFourEightTwoOneColor("rgba(232,245,233,1)");
  };
  const privacyFourSixFourEightFourEightTwoOneOnMouseOver = () => {
    setPrivacyFourSixFourEightFourEightTwoOneColor("green");
  };
  const setPWOnClick = useNavigateAction({
    type: "url",
    url: "/PasswordResset",
  });
  const setPWOnMouseOver = () => {
    setSetPWColor("green");
  };
  const setPWOnMouseLeave = () => {
    setSetPWColor("rgba(232,245,233,1)");
  };
  return (
    <View
      width="79px"
      height="759px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(58,86,66,1)"
      {...getOverrideProps(overrides, "SideBar2")}
      {...rest}
    >
      <Flex
        gap="12px"
        direction="column"
        width="100px"
        height="425px"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="0px"
        left="calc(50% - 50px - -0.5px)"
        padding="66px 0px 68px 0px"
        {...getOverrideProps(overrides, "Frame 413")}
      >
        <View
          width="79px"
          height="29px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Home34973005")}
        >
          <View
            width="24px"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon")}
          >
            <Icon
              width="20px"
              height="17px"
              viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
              paths={[
                {
                  d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                  fill: "rgba(49,240,63,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="12.5%"
              bottom="16.67%"
              left="8.33%"
              right="8.33%"
              {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </View>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={homeThreeFourNineSevenThreeZeroZeroEightColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="2px"
            left="27px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Home"
            onClick={() => {
              homeThreeFourNineSevenThreeZeroZeroEightOnClick();
            }}
            onMouseLeave={() => {
              homeThreeFourNineSevenThreeZeroZeroEightOnMouseLeave();
            }}
            onMouseOver={() => {
              homeThreeFourNineSevenThreeZeroZeroEightOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Home34973008")}
          ></Text>
        </View>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Automation")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={automateColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Automate"
            onClick={() => {
              automateOnClick();
            }}
            onMouseLeave={() => {
              automateOnMouseLeave();
            }}
            onMouseOver={() => {
              automateOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Automate")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "SiteInfo")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={siteInfoColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Site Info"
            onClick={() => {
              siteInfoOnClick();
            }}
            onMouseLeave={() => {
              siteInfoOnMouseLeave();
            }}
            onMouseOver={() => {
              siteInfoOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Site Info")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Reporting34973017")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={reportingThreeFourNineSevenThreeZeroTwoZeroColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Reporting"
            onClick={() => {
              reportingThreeFourNineSevenThreeZeroTwoZeroOnClick();
            }}
            onMouseLeave={() => {
              reportingThreeFourNineSevenThreeZeroTwoZeroOnMouseLeave();
            }}
            onMouseOver={() => {
              reportingThreeFourNineSevenThreeZeroTwoZeroOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Reporting34973020")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Animations")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={animationColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Animation"
            onClick={() => {
              animationOnClick();
            }}
            onMouseLeave={() => {
              animationOnMouseLeave();
            }}
            onMouseOver={() => {
              animationOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Animation")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Trading34973025")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={tradingThreeFourNineSevenThreeZeroTwoEightColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Trading"
            onClick={() => {
              tradingThreeFourNineSevenThreeZeroTwoEightOnClick();
            }}
            onMouseLeave={() => {
              tradingThreeFourNineSevenThreeZeroTwoEightOnMouseLeave();
            }}
            onMouseOver={() => {
              tradingThreeFourNineSevenThreeZeroTwoEightOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Trading34973028")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Changesite")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={sitesColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Sites"
            onClick={() => {
              sitesOnClick();
            }}
            onMouseLeave={() => {
              sitesOnMouseLeave();
            }}
            onMouseOver={() => {
              sitesOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Sites")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Financial34973033")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={financialThreeFourNineSevenThreeZeroThreeSixColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Financial"
            onClick={() => {
              financialThreeFourNineSevenThreeZeroThreeSixOnClick();
            }}
            onMouseLeave={() => {
              financialThreeFourNineSevenThreeZeroThreeSixOnMouseLeave();
            }}
            onMouseOver={() => {
              financialThreeFourNineSevenThreeZeroThreeSixOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Financial34973036")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Privacy46484818")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={privacyFourSixFourEightFourEightTwoOneColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Privacy"
            onClick={() => {
              privacyFourSixFourEightFourEightTwoOneOnClick();
            }}
            onMouseLeave={() => {
              privacyFourSixFourEightFourEightTwoOneOnMouseLeave();
            }}
            onMouseOver={() => {
              privacyFourSixFourEightFourEightTwoOneOnMouseOver();
            }}
            {...getOverrideProps(overrides, "Privacy46484821")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "PassWord")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color={setPWColor}
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="SetPW"
            onClick={() => {
              setPWOnClick();
            }}
            onMouseOver={() => {
              setPWOnMouseOver();
            }}
            onMouseLeave={() => {
              setPWOnMouseLeave();
            }}
            {...getOverrideProps(overrides, "Set PW")}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
