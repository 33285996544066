/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIndicator = /* GraphQL */ `
  query GetIndicator($id: ID!) {
    getIndicator(id: $id) {
      id
      AModel
      Brand
      OS
      OS_version
      WiFi
      Zigbee
      Zwave
      Light
      Other
      ASiteName
      Subscribed
      Status
      Comment
      Indic_serial
      Dev_Name
      DeviceIP
      Dev_Order
      UserNam
      Password
      GateWayIp
      MAC
      Ssid
      Ssid_Pw
      LastUpdate
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Set_Colour
      Reported_Colour
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      indicatorSite_idId
    }
  }
`;
export const listIndicators = /* GraphQL */ `
  query ListIndicators(
    $filter: ModelIndicatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndicators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AModel
        Brand
        OS
        OS_version
        WiFi
        Zigbee
        Zwave
        Light
        Other
        ASiteName
        Subscribed
        Status
        Comment
        Indic_serial
        Dev_Name
        DeviceIP
        Dev_Order
        UserNam
        Password
        GateWayIp
        MAC
        Ssid
        Ssid_Pw
        LastUpdate
        Set_Colour
        Reported_Colour
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        indicatorSite_idId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIndicators = /* GraphQL */ `
  query SyncIndicators(
    $filter: ModelIndicatorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIndicators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        AModel
        Brand
        OS
        OS_version
        WiFi
        Zigbee
        Zwave
        Light
        Other
        ASiteName
        Subscribed
        Status
        Comment
        Indic_serial
        Dev_Name
        DeviceIP
        Dev_Order
        UserNam
        Password
        GateWayIp
        MAC
        Ssid
        Ssid_Pw
        LastUpdate
        Set_Colour
        Reported_Colour
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        indicatorSite_idId
      }
      nextToken
      startedAt
    }
  }
`;
export const getUntitledModel = /* GraphQL */ `
  query GetUntitledModel($id: ID!) {
    getUntitledModel(id: $id) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUntitledModels = /* GraphQL */ `
  query ListUntitledModels(
    $filter: ModelUntitledModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUntitledModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUntitledModels = /* GraphQL */ `
  query SyncUntitledModels(
    $filter: ModelUntitledModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUntitledModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLoadCtl = /* GraphQL */ `
  query GetLoadCtl($id: ID!) {
    getLoadCtl(id: $id) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      GateWayIp
      OtherIP
      UserNam
      Password
      AModel
      MAC
      OS_Version
      OS
      WiFi
      Eth
      Zigbee
      Zwave
      Brand
      SSiD
      SSiD_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_PowerW
      Last_Contact
      Device_Install
      Status
      Subscribed
      State
      Overide
      Default_Margin
      Current_Setting
      DeviceType
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      loadCtlSite_idId
      owner
    }
  }
`;
export const listLoadCtls = /* GraphQL */ `
  query ListLoadCtls(
    $filter: ModelLoadCtlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoadCtls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ASiteName
        DeviceIP
        DeviceNetwork
        Dev_Order
        DeviceName
        GateWayIp
        OtherIP
        UserNam
        Password
        AModel
        MAC
        OS_Version
        OS
        WiFi
        Eth
        Zigbee
        Zwave
        Brand
        SSiD
        SSiD_Pw
        PoE
        LastUpdate
        Temperature
        Last_Voltage
        Last_PowerW
        Last_Contact
        Device_Install
        Status
        Subscribed
        State
        Overide
        Default_Margin
        Current_Setting
        DeviceType
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        loadCtlSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLoadCtls = /* GraphQL */ `
  query SyncLoadCtls(
    $filter: ModelLoadCtlFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLoadCtls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        DeviceIP
        DeviceNetwork
        Dev_Order
        DeviceName
        GateWayIp
        OtherIP
        UserNam
        Password
        AModel
        MAC
        OS_Version
        OS
        WiFi
        Eth
        Zigbee
        Zwave
        Brand
        SSiD
        SSiD_Pw
        PoE
        LastUpdate
        Temperature
        Last_Voltage
        Last_PowerW
        Last_Contact
        Device_Install
        Status
        Subscribed
        State
        Overide
        Default_Margin
        Current_Setting
        DeviceType
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        loadCtlSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccountItems = /* GraphQL */ `
  query GetAccountItems($id: ID!) {
    getAccountItems(id: $id) {
      id
      ItemDescript
      Item_Num
      GenDiscount
      BasePrice
      ActualPrice
      Cost
      Default_Item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAccountItems = /* GraphQL */ `
  query ListAccountItems(
    $filter: ModelAccountItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ItemDescript
        Item_Num
        GenDiscount
        BasePrice
        ActualPrice
        Cost
        Default_Item
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccountItems = /* GraphQL */ `
  query SyncAccountItems(
    $filter: ModelAccountItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ItemDescript
        Item_Num
        GenDiscount
        BasePrice
        ActualPrice
        Cost
        Default_Item
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFinancial = /* GraphQL */ `
  query GetFinancial($id: ID!) {
    getFinancial(id: $id) {
      id
      Item_num
      ItemDescript
      NumofItems
      Discount
      BasePrice
      ActualPrice
      Cost
      CustomerType
      PayGateway
      PayGateway2
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Tot_Outstanding
      Current
      ASiteNam
      Order
      Bill_Currency
      Subscribed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      financialSite_idId
      owner
    }
  }
`;
export const listFinancials = /* GraphQL */ `
  query ListFinancials(
    $filter: ModelFinancialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Item_num
        ItemDescript
        NumofItems
        Discount
        BasePrice
        ActualPrice
        Cost
        CustomerType
        PayGateway
        PayGateway2
        Tot_Outstanding
        Current
        ASiteNam
        Order
        Bill_Currency
        Subscribed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        financialSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFinancials = /* GraphQL */ `
  query SyncFinancials(
    $filter: ModelFinancialFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFinancials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Item_num
        ItemDescript
        NumofItems
        Discount
        BasePrice
        ActualPrice
        Cost
        CustomerType
        PayGateway
        PayGateway2
        Tot_Outstanding
        Current
        ASiteNam
        Order
        Bill_Currency
        Subscribed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        financialSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getIoTTypes = /* GraphQL */ `
  query GetIoTTypes($id: ID!) {
    getIoTTypes(id: $id) {
      id
      AModel
      Brand
      Dev_Name
      OS
      OS_version
      WiFi
      Eth
      Zigbee
      Zwave
      RFBridge
      Switch
      Light
      Plug
      Curtain
      Dimmer
      Garage
      Fan
      Heater
      Kettle
      IRController
      Bell
      Valve
      Zigbee_GW
      Sensor_Smoke
      Sensor_Energy
      Rating
      Sensor_Temp
      Sensor_damp
      Sensor_Water_level
      Sensor_Contact
      Sensor_Occupation
      Sensor_Proximity
      Sensor_Gas
      Sensor_Illum
      Sensor_Motion
      Other
      Channels
      Category
      Comments
      IoTandDevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listIoTTypes = /* GraphQL */ `
  query ListIoTTypes(
    $filter: ModelIoTTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIoTTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AModel
        Brand
        Dev_Name
        OS
        OS_version
        WiFi
        Eth
        Zigbee
        Zwave
        RFBridge
        Switch
        Light
        Plug
        Curtain
        Dimmer
        Garage
        Fan
        Heater
        Kettle
        IRController
        Bell
        Valve
        Zigbee_GW
        Sensor_Smoke
        Sensor_Energy
        Rating
        Sensor_Temp
        Sensor_damp
        Sensor_Water_level
        Sensor_Contact
        Sensor_Occupation
        Sensor_Proximity
        Sensor_Gas
        Sensor_Illum
        Sensor_Motion
        Other
        Channels
        Category
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIoTTypes = /* GraphQL */ `
  query SyncIoTTypes(
    $filter: ModelIoTTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIoTTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        AModel
        Brand
        Dev_Name
        OS
        OS_version
        WiFi
        Eth
        Zigbee
        Zwave
        RFBridge
        Switch
        Light
        Plug
        Curtain
        Dimmer
        Garage
        Fan
        Heater
        Kettle
        IRController
        Bell
        Valve
        Zigbee_GW
        Sensor_Smoke
        Sensor_Energy
        Rating
        Sensor_Temp
        Sensor_damp
        Sensor_Water_level
        Sensor_Contact
        Sensor_Occupation
        Sensor_Proximity
        Sensor_Gas
        Sensor_Illum
        Sensor_Motion
        Other
        Channels
        Category
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getImages = /* GraphQL */ `
  query GetImages($id: ID!) {
    getImages(id: $id) {
      id
      cactii
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      ASiteN
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      imagesSite_idId
      owner
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cactii
        Dev_Order
        ASiteN
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        imagesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncImages = /* GraphQL */ `
  query SyncImages(
    $filter: ModelImagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cactii
        Dev_Order
        ASiteN
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        imagesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getBeSTypes = /* GraphQL */ `
  query GetBeSTypes($id: ID!) {
    getBeSTypes(id: $id) {
      id
      TypeCode
      AModel
      Brand
      Capacity
      Modular
      SinglePh
      ThreePh
      reGenAuth
      ApIExpire
      reGenMasterKey
      reGenUrL
      Comments
      MaxOut
      Hybrid
      BEsSystems {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listBeSTypes = /* GraphQL */ `
  query ListBeSTypes(
    $filter: ModelBeSTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeSTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TypeCode
        AModel
        Brand
        Capacity
        Modular
        SinglePh
        ThreePh
        reGenAuth
        ApIExpire
        reGenMasterKey
        reGenUrL
        Comments
        MaxOut
        Hybrid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBeSTypes = /* GraphQL */ `
  query SyncBeSTypes(
    $filter: ModelBeSTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBeSTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        TypeCode
        AModel
        Brand
        Capacity
        Modular
        SinglePh
        ThreePh
        reGenAuth
        ApIExpire
        reGenMasterKey
        reGenUrL
        Comments
        MaxOut
        Hybrid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      SiteName
      AFriendlyName
      SiteContact
      SUnit
      SStreet
      SSuburb
      SPostCode
      SState
      SCountry
      SLongitude
      SLatitude
      SElevation
      SPhases
      SRouter
      SRouterComment
      SiteComment
      SiteCommision
      SiteOnPortal
      SiteTrading
      SEnSclupr
      SEnScllwr
      EnergyRetailer
      NmI
      PrimeUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      BillContact {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SiteContct {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SecUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Guest {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      EnergyTot
      EnergyPh1
      EnergyPh2
      EnergyPh3
      SaveVsRes
      ResveTrade
      ClimateMorn
      ClimateMid
      ClimateArvo
      PauseAutomation
      MinPeriodPool
      Include
      UseEvBattery
      EvSoCmin
      Alert1
      AlertTime
      Alert2
      AlertTime2
      OpenSolariD
      Pool_On
      Offline_IoT
      OsStatus
      Indic_MuteStart
      Indic_MuteStop
      Indic_Max_day
      Indic_Max_night
      Indic_Sunrise_pause
      Indic_temp_off
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sitePrimeUserId
      siteBillContactId
      siteSiteContctId
      siteSecUserId
      siteGuestId
      owner
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSites = /* GraphQL */ `
  query SyncSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getEMdevices = /* GraphQL */ `
  query GetEMdevices($id: ID!) {
    getEMdevices(id: $id) {
      id
      SiteName
      AAsiteName
      EmBrand
      EmModel
      EmID
      Read
      Network
      Ch1Label
      Ch2Label
      Ch3Label
      Ch4Label
      Ch5Label
      Ch6Label
      Ch7Label
      Ch8Label
      Antenna
      EmActivation
      EmPhases
      Status
      Comments
      Modbus
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      EmSwitchUsed
      OpenSolarID
      Dev_Order
      SSID
      Subscribed
      State
      AryPosPh1
      AryPosPh2
      AryPosPh3
      AryPosS1Ph1
      AryPosS1Ph2
      AryPosS1Ph3
      AryPosS2Ph1
      AryPosS2Ph2
      AryPosS2Ph3
      ActivationId
      OPENSOLARID
      emdevtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eMdevicesSite_idId
      owner
    }
  }
`;
export const listEMdevices = /* GraphQL */ `
  query ListEMdevices(
    $filter: ModelEMdevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEMdevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        SiteName
        AAsiteName
        EmBrand
        EmModel
        EmID
        Read
        Network
        Ch1Label
        Ch2Label
        Ch3Label
        Ch4Label
        Ch5Label
        Ch6Label
        Ch7Label
        Ch8Label
        Antenna
        EmActivation
        EmPhases
        Status
        Comments
        Modbus
        EmSwitchUsed
        OpenSolarID
        Dev_Order
        SSID
        Subscribed
        State
        AryPosPh1
        AryPosPh2
        AryPosPh3
        AryPosS1Ph1
        AryPosS1Ph2
        AryPosS1Ph3
        AryPosS2Ph1
        AryPosS2Ph2
        AryPosS2Ph3
        ActivationId
        OPENSOLARID
        emdevtypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eMdevicesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEMdevices = /* GraphQL */ `
  query SyncEMdevices(
    $filter: ModelEMdevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEMdevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SiteName
        AAsiteName
        EmBrand
        EmModel
        EmID
        Read
        Network
        Ch1Label
        Ch2Label
        Ch3Label
        Ch4Label
        Ch5Label
        Ch6Label
        Ch7Label
        Ch8Label
        Antenna
        EmActivation
        EmPhases
        Status
        Comments
        Modbus
        EmSwitchUsed
        OpenSolarID
        Dev_Order
        SSID
        Subscribed
        State
        AryPosPh1
        AryPosPh2
        AryPosPh3
        AryPosS1Ph1
        AryPosS1Ph2
        AryPosS1Ph3
        AryPosS2Ph1
        AryPosS2Ph2
        AryPosS2Ph3
        ActivationId
        OPENSOLARID
        emdevtypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eMdevicesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($id: ID!) {
    getContacts(id: $id) {
      id
      AFullName
      LastName
      FirstName
      Unit
      Street
      Suburb
      State
      PostC
      Country
      Mobile
      email
      MobileVerified
      emailVerified
      PrefContactMethod
      AgreedClickWrap
      Gender
      Comments
      currentpassword
      oldpassword
      numSites
      LastViewedSite
      LastViewediD
      Dateviewed
      Site1
      RoleS1
      SiteiD1
      Site2
      RoleS2
      SiteiD2
      Site3
      RoleS3
      SiteiD3
      Site4
      RoleS4
      SiteiD4
      Site5
      RoleS5
      SiteiD5
      AuthStatus
      AuthStatusDate
      AuthStatusComment
      LastAccessDate
      CognitoID
      OsContactId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmDevtypes = /* GraphQL */ `
  query GetEmDevtypes($id: ID!) {
    getEmDevtypes(id: $id) {
      id
      Model
      AShortModel
      EmBrand
      Channels
      Modbus
      Switch
      reGenAuth
      reGenKey
      Commsmeth
      reGenUrl
      Comments
      EMdevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listEmDevtypes = /* GraphQL */ `
  query ListEmDevtypes(
    $filter: ModelEmDevtypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmDevtypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Model
        AShortModel
        EmBrand
        Channels
        Modbus
        Switch
        reGenAuth
        reGenKey
        Commsmeth
        reGenUrl
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmDevtypes = /* GraphQL */ `
  query SyncEmDevtypes(
    $filter: ModelEmDevtypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmDevtypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Model
        AShortModel
        EmBrand
        Channels
        Modbus
        Switch
        reGenAuth
        reGenKey
        Commsmeth
        reGenUrl
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getEnosiSetting = /* GraphQL */ `
  query GetEnosiSetting($id: ID!) {
    getEnosiSetting(id: $id) {
      id
      ACustGivenName
      TCustEmail
      TCustFamilyname
      TPropName
      ImpAvePrice
      ImpTradedvalue
      ImpTradedenergy
      ExpAvePrice
      ExpTradedvalue
      ExpTradedenergy
      TPeriodstart
      TPeriodEnd
      TMeter1Num
      TMeter2Num
      TMeter3Num
      TNomSell1
      TNomSell2
      TNomSell3
      TNomBuy1
      TNomBuy2
      TNomBuy3
      TResidSell1
      TResidSell2
      TResidBuy1
      TResidBuy2
      TID1
      TTimestampNow
      TID2
      TTimestampLast
      TRuleIBuy1
      TBuyPt1
      TRuleIBuy2
      TBuyPt2
      TRuleBuy3
      TBuyPt3
      TRuleSell1
      TSellPt1
      TRuleSell2
      TSellPt2
      TRuleSell3
      TSellPt3
      WarningMessageNum
      NewTradeMessages
      ASiteName
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      enosiSettingSite_idId
      owner
    }
  }
`;
export const listEnosiSettings = /* GraphQL */ `
  query ListEnosiSettings(
    $filter: ModelEnosiSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnosiSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ACustGivenName
        TCustEmail
        TCustFamilyname
        TPropName
        ImpAvePrice
        ImpTradedvalue
        ImpTradedenergy
        ExpAvePrice
        ExpTradedvalue
        ExpTradedenergy
        TPeriodstart
        TPeriodEnd
        TMeter1Num
        TMeter2Num
        TMeter3Num
        TNomSell1
        TNomSell2
        TNomSell3
        TNomBuy1
        TNomBuy2
        TNomBuy3
        TResidSell1
        TResidSell2
        TResidBuy1
        TResidBuy2
        TID1
        TTimestampNow
        TID2
        TTimestampLast
        TRuleIBuy1
        TBuyPt1
        TRuleIBuy2
        TBuyPt2
        TRuleBuy3
        TBuyPt3
        TRuleSell1
        TSellPt1
        TRuleSell2
        TSellPt2
        TRuleSell3
        TSellPt3
        WarningMessageNum
        NewTradeMessages
        ASiteName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        enosiSettingSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEnosiSettings = /* GraphQL */ `
  query SyncEnosiSettings(
    $filter: ModelEnosiSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEnosiSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ACustGivenName
        TCustEmail
        TCustFamilyname
        TPropName
        ImpAvePrice
        ImpTradedvalue
        ImpTradedenergy
        ExpAvePrice
        ExpTradedvalue
        ExpTradedenergy
        TPeriodstart
        TPeriodEnd
        TMeter1Num
        TMeter2Num
        TMeter3Num
        TNomSell1
        TNomSell2
        TNomSell3
        TNomBuy1
        TNomBuy2
        TNomBuy3
        TResidSell1
        TResidSell2
        TResidBuy1
        TResidBuy2
        TID1
        TTimestampNow
        TID2
        TTimestampLast
        TRuleIBuy1
        TBuyPt1
        TRuleIBuy2
        TBuyPt2
        TRuleBuy3
        TBuyPt3
        TRuleSell1
        TSellPt1
        TRuleSell2
        TSellPt2
        TRuleSell3
        TSellPt3
        WarningMessageNum
        NewTradeMessages
        ASiteName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        enosiSettingSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvChargers = /* GraphQL */ `
  query GetEvChargers($id: ID!) {
    getEvChargers(id: $id) {
      id
      ASiteName
      EvChBrand
      EvChModel
      EvChCapacity
      EvChApiaddr
      EvChApiKey
      EvChSerialNum
      EvChPhases
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      BoostUntilSoC
      PriotisedSoC
      Only_Solar
      Dev_Order
      Overide
      Status
      State
      Subscribed
      Comments
      Device_Install
      Ssid
      Temperature
      DeviceIP
      DeviceName
      Last_Voltage
      Last_PowerW
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      evChargersSite_idId
      owner
    }
  }
`;
export const listEvChargers = /* GraphQL */ `
  query ListEvChargers(
    $filter: ModelEvChargersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvChargers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ASiteName
        EvChBrand
        EvChModel
        EvChCapacity
        EvChApiaddr
        EvChApiKey
        EvChSerialNum
        EvChPhases
        BoostUntilSoC
        PriotisedSoC
        Only_Solar
        Dev_Order
        Overide
        Status
        State
        Subscribed
        Comments
        Device_Install
        Ssid
        Temperature
        DeviceIP
        DeviceName
        Last_Voltage
        Last_PowerW
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        evChargersSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvChargers = /* GraphQL */ `
  query SyncEvChargers(
    $filter: ModelEvChargersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvChargers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        EvChBrand
        EvChModel
        EvChCapacity
        EvChApiaddr
        EvChApiKey
        EvChSerialNum
        EvChPhases
        BoostUntilSoC
        PriotisedSoC
        Only_Solar
        Dev_Order
        Overide
        Status
        State
        Subscribed
        Comments
        Device_Install
        Ssid
        Temperature
        DeviceIP
        DeviceName
        Last_Voltage
        Last_PowerW
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        evChargersSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getWattWatchperAPI = /* GraphQL */ `
  query GetWattWatchperAPI($id: ID!) {
    getWattWatchperAPI(id: $id) {
      id
      AASite
      WWID
      APIKey
      APIAddress
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listWattWatchperAPIS = /* GraphQL */ `
  query ListWattWatchperAPIS(
    $filter: ModelWattWatchperAPIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWattWatchperAPIS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AASite
        WWID
        APIKey
        APIAddress
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWattWatchperAPIS = /* GraphQL */ `
  query SyncWattWatchperAPIS(
    $filter: ModelWattWatchperAPIFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWattWatchperAPIS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        AASite
        WWID
        APIKey
        APIAddress
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getEnergyNow = /* GraphQL */ `
  query GetEnergyNow($id: ID!) {
    getEnergyNow(id: $id) {
      id
      ASiteName
      Timestamp
      Frequency
      SolarAry
      GridAry
      StorageAry
      OtherAryy
      HWSAry
      IndicatorAry
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      energyNowSite_idId
      owner
    }
  }
`;
export const listEnergyNows = /* GraphQL */ `
  query ListEnergyNows(
    $filter: ModelEnergyNowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnergyNows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ASiteName
        Timestamp
        Frequency
        SolarAry
        GridAry
        StorageAry
        OtherAryy
        HWSAry
        IndicatorAry
        Order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        energyNowSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEnergyNows = /* GraphQL */ `
  query SyncEnergyNows(
    $filter: ModelEnergyNowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEnergyNows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        Timestamp
        Frequency
        SolarAry
        GridAry
        StorageAry
        OtherAryy
        HWSAry
        IndicatorAry
        Order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        energyNowSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getOnsiteProcessor = /* GraphQL */ `
  query GetOnsiteProcessor($id: ID!) {
    getOnsiteProcessor(id: $id) {
      id
      ASiteName
      DeviceName
      GreenGrassName
      HostName
      GateWayIP
      LocalIP
      ZeroTierIp
      MAC
      OS
      Processor
      UserName
      UPassword
      Ssid
      Ssid_Password
      POE
      LastUpdate
      Temperature
      Other
      LastContact
      DeviceInstall
      Comments
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Subscribed
      Status
      State
      Tx_Ssid
      Tx_Ssid_Pw
      Portal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      onsiteProcessorSite_idId
      owner
    }
  }
`;
export const listOnsiteProcessors = /* GraphQL */ `
  query ListOnsiteProcessors(
    $filter: ModelOnsiteProcessorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnsiteProcessors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ASiteName
        DeviceName
        GreenGrassName
        HostName
        GateWayIP
        LocalIP
        ZeroTierIp
        MAC
        OS
        Processor
        UserName
        UPassword
        Ssid
        Ssid_Password
        POE
        LastUpdate
        Temperature
        Other
        LastContact
        DeviceInstall
        Comments
        Dev_Order
        Subscribed
        Status
        State
        Tx_Ssid
        Tx_Ssid_Pw
        Portal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        onsiteProcessorSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOnsiteProcessors = /* GraphQL */ `
  query SyncOnsiteProcessors(
    $filter: ModelOnsiteProcessorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOnsiteProcessors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        DeviceName
        GreenGrassName
        HostName
        GateWayIP
        LocalIP
        ZeroTierIp
        MAC
        OS
        Processor
        UserName
        UPassword
        Ssid
        Ssid_Password
        POE
        LastUpdate
        Temperature
        Other
        LastContact
        DeviceInstall
        Comments
        Dev_Order
        Subscribed
        Status
        State
        Tx_Ssid
        Tx_Ssid_Pw
        Portal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        onsiteProcessorSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSiteSolarInverter = /* GraphQL */ `
  query GetSiteSolarInverter($id: ID!) {
    getSiteSolarInverter(id: $id) {
      id
      ASiteName
      SolarModel
      SolarBrand
      SolarSerialNum
      RegNum
      SolarPhasesCtd
      SolarP1
      SolarP2
      SolarP3
      APIAddress
      SolarAPIKey
      EPSCtd
      SiteSolarDeviceNum
      SitePanelPeakCtd
      SolarCommsNet
      Dongle
      Hybrid
      CommDate
      SiteName
      Comments
      Dev_Order
      Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      PowerNow
      Subscribed
      State
      solarinvertertypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      siteSolarInverterSite_idId
      owner
    }
  }
`;
export const listSiteSolarInverters = /* GraphQL */ `
  query ListSiteSolarInverters(
    $filter: ModelSiteSolarInverterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteSolarInverters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ASiteName
        SolarModel
        SolarBrand
        SolarSerialNum
        RegNum
        SolarPhasesCtd
        SolarP1
        SolarP2
        SolarP3
        APIAddress
        SolarAPIKey
        EPSCtd
        SiteSolarDeviceNum
        SitePanelPeakCtd
        SolarCommsNet
        Dongle
        Hybrid
        CommDate
        SiteName
        Comments
        Dev_Order
        Status
        PowerNow
        Subscribed
        State
        solarinvertertypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        siteSolarInverterSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSiteSolarInverters = /* GraphQL */ `
  query SyncSiteSolarInverters(
    $filter: ModelSiteSolarInverterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSiteSolarInverters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        SolarModel
        SolarBrand
        SolarSerialNum
        RegNum
        SolarPhasesCtd
        SolarP1
        SolarP2
        SolarP3
        APIAddress
        SolarAPIKey
        EPSCtd
        SiteSolarDeviceNum
        SitePanelPeakCtd
        SolarCommsNet
        Dongle
        Hybrid
        CommDate
        SiteName
        Comments
        Dev_Order
        Status
        PowerNow
        Subscribed
        State
        solarinvertertypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        siteSolarInverterSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getIoTandDevices = /* GraphQL */ `
  query GetIoTandDevices($id: ID!) {
    getIoTandDevices(id: $id) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      HostName
      GateWayIp
      OtherIP
      Mac
      UserNam
      Password
      Ssid
      Ssid_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_current
      Last_PowerW
      Last_VaR
      Last_Image
      Last_Other1
      Last_Other2
      Last_Contact
      Device_Install
      Status
      Comments
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Used_Where
      Overide
      State
      DeviceType
      OS
      Subscribed
      Processor
      Tx_Ssid
      Set_Colour
      Reported_Colour
      iottypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ioTandDevicesSite_idId
      owner
    }
  }
`;
export const listIoTandDevices = /* GraphQL */ `
  query ListIoTandDevices(
    $filter: ModelIoTandDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIoTandDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ASiteName
        DeviceIP
        DeviceNetwork
        Dev_Order
        DeviceName
        HostName
        GateWayIp
        OtherIP
        Mac
        UserNam
        Password
        Ssid
        Ssid_Pw
        PoE
        LastUpdate
        Temperature
        Last_Voltage
        Last_current
        Last_PowerW
        Last_VaR
        Last_Image
        Last_Other1
        Last_Other2
        Last_Contact
        Device_Install
        Status
        Comments
        Used_Where
        Overide
        State
        DeviceType
        OS
        Subscribed
        Processor
        Tx_Ssid
        Set_Colour
        Reported_Colour
        iottypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ioTandDevicesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIoTandDevices = /* GraphQL */ `
  query SyncIoTandDevices(
    $filter: ModelIoTandDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIoTandDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        DeviceIP
        DeviceNetwork
        Dev_Order
        DeviceName
        HostName
        GateWayIp
        OtherIP
        Mac
        UserNam
        Password
        Ssid
        Ssid_Pw
        PoE
        LastUpdate
        Temperature
        Last_Voltage
        Last_current
        Last_PowerW
        Last_VaR
        Last_Image
        Last_Other1
        Last_Other2
        Last_Contact
        Device_Install
        Status
        Comments
        Used_Where
        Overide
        State
        DeviceType
        OS
        Subscribed
        Processor
        Tx_Ssid
        Set_Colour
        Reported_Colour
        iottypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ioTandDevicesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getBEsSystems = /* GraphQL */ `
  query GetBEsSystems($id: ID!) {
    getBEsSystems(id: $id) {
      id
      ASiteName
      EsBrand
      EsModel
      EsType
      EsApiAddr
      EsApiKey
      EsPower
      EsSerialNumber
      EsStateOfCharge
      EsPhases
      ESCapacity
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      State
      Status
      Trade_off_Battery
      Subscribed
      bestypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bEsSystemsSite_idId
      owner
    }
  }
`;
export const listBEsSystems = /* GraphQL */ `
  query ListBEsSystems(
    $filter: ModelBEsSystemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBEsSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ASiteName
        EsBrand
        EsModel
        EsType
        EsApiAddr
        EsApiKey
        EsPower
        EsSerialNumber
        EsStateOfCharge
        EsPhases
        ESCapacity
        Dev_Order
        State
        Status
        Trade_off_Battery
        Subscribed
        bestypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bEsSystemsSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBEsSystems = /* GraphQL */ `
  query SyncBEsSystems(
    $filter: ModelBEsSystemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBEsSystems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ASiteName
        EsBrand
        EsModel
        EsType
        EsApiAddr
        EsApiKey
        EsPower
        EsSerialNumber
        EsStateOfCharge
        EsPhases
        ESCapacity
        Dev_Order
        State
        Status
        Trade_off_Battery
        Subscribed
        bestypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bEsSystemsSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSolarInverterTypes = /* GraphQL */ `
  query GetSolarInverterTypes($id: ID!) {
    getSolarInverterTypes(id: $id) {
      id
      TypeCode
      AModel
      Phases
      PeakPower
      Hybrid
      RSolarInv {
        nextToken
        startedAt
      }
      Brand
      ApiAddress
      ApiKey
      reGenAuth
      reGenMaster
      reGenURL
      ApIKeyexpire
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listSolarInverterTypes = /* GraphQL */ `
  query ListSolarInverterTypes(
    $filter: ModelSolarInverterTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolarInverterTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TypeCode
        AModel
        Phases
        PeakPower
        Hybrid
        Brand
        ApiAddress
        ApiKey
        reGenAuth
        reGenMaster
        reGenURL
        ApIKeyexpire
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSolarInverterTypes = /* GraphQL */ `
  query SyncSolarInverterTypes(
    $filter: ModelSolarInverterTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSolarInverterTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        TypeCode
        AModel
        Phases
        PeakPower
        Hybrid
        Brand
        ApiAddress
        ApiKey
        reGenAuth
        reGenMaster
        reGenURL
        ApIKeyexpire
        Comments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLifXTypes = /* GraphQL */ `
  query GetLifXTypes($id: ID!) {
    getLifXTypes(id: $id) {
      id
      ModelNum
      AModelNum
      Fitting
      Lumens
      Format
      Type
      Comments
      ColourOptions
      LiFxes {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listLifXTypes = /* GraphQL */ `
  query ListLifXTypes(
    $filter: ModelLifXTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLifXTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ModelNum
        AModelNum
        Fitting
        Lumens
        Format
        Type
        Comments
        ColourOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLifXTypes = /* GraphQL */ `
  query SyncLifXTypes(
    $filter: ModelLifXTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLifXTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ModelNum
        AModelNum
        Fitting
        Lumens
        Format
        Type
        Comments
        ColourOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getOtherDevices = /* GraphQL */ `
  query GetOtherDevices($id: ID!) {
    getOtherDevices(id: $id) {
      id
      DeviceType
      ASiteName
      SerialNum
      Device_name
      Device_Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      Desription
      Comments
      Overide
      State
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      otherDevicesSite_idId
      owner
    }
  }
`;
export const listOtherDevices = /* GraphQL */ `
  query ListOtherDevices(
    $filter: ModelOtherDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOtherDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        DeviceType
        ASiteName
        SerialNum
        Device_name
        Device_Status
        Dev_Order
        Desription
        Comments
        Overide
        State
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        otherDevicesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOtherDevices = /* GraphQL */ `
  query SyncOtherDevices(
    $filter: ModelOtherDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOtherDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        DeviceType
        ASiteName
        SerialNum
        Device_name
        Device_Status
        Dev_Order
        Desription
        Comments
        Overide
        State
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        otherDevicesSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLiFx = /* GraphQL */ `
  query GetLiFx($id: ID!) {
    getLiFx(id: $id) {
      id
      ALifxID
      LifXType
      AaSiteName
      Primary
      MuteStart
      MuteStop
      Max_Day_bright
      Max_Night_bright
      LifXApIKey
      ApIaddress
      LifXColour
      ColourPh1
      ColourPh2
      ColourPh3
      Dev_Order
      SunrisePause
      Subscribed
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Status
      lifxtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liFxSite_idId
      owner
    }
  }
`;
export const listLiFxes = /* GraphQL */ `
  query ListLiFxes(
    $filter: ModelLiFxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLiFxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ALifxID
        LifXType
        AaSiteName
        Primary
        MuteStart
        MuteStop
        Max_Day_bright
        Max_Night_bright
        LifXApIKey
        ApIaddress
        LifXColour
        ColourPh1
        ColourPh2
        ColourPh3
        Dev_Order
        SunrisePause
        Subscribed
        Status
        lifxtypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        liFxSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLiFxes = /* GraphQL */ `
  query SyncLiFxes(
    $filter: ModelLiFxFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLiFxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ALifxID
        LifXType
        AaSiteName
        Primary
        MuteStart
        MuteStop
        Max_Day_bright
        Max_Night_bright
        LifXApIKey
        ApIaddress
        LifXColour
        ColourPh1
        ColourPh2
        ColourPh3
        Dev_Order
        SunrisePause
        Subscribed
        Status
        lifxtypesID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        liFxSite_idId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
