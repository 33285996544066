// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Indicator, UntitledModel, LoadCtl, AccountItems, Financial, IoTTypes, Images, BeSTypes, Site, EMdevices, Contacts, EmDevtypes, EnosiSetting, EvChargers, WattWatchperAPI, EnergyNow, OnsiteProcessor, SiteSolarInverter, IoTandDevices, BEsSystems, SolarInverterTypes, LifXTypes, OtherDevices, LiFx } = initSchema(schema);

export {
  Indicator,
  UntitledModel,
  LoadCtl,
  AccountItems,
  Financial,
  IoTTypes,
  Images,
  BeSTypes,
  Site,
  EMdevices,
  Contacts,
  EmDevtypes,
  EnosiSetting,
  EvChargers,
  WattWatchperAPI,
  EnergyNow,
  OnsiteProcessor,
  SiteSolarInverter,
  IoTandDevices,
  BEsSystems,
  SolarInverterTypes,
  LifXTypes,
  OtherDevices,
  LiFx
};