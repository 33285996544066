import * as React from "react";
import { useEffect,useState  } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  Button,
  Flex,
  Heading,
  PasswordField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";


export default function CustomSignUp() {

  const [message, setMessage] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [checkBox, setCheckBox] = useState(false);
  const login = (<a href="/signin">Click here to SignIn</a>);

  async function ValidateSignUp() {

    let contacts = await API.graphql({...graphqlOperation(queries.listContacts, { filter: { email: { eq :email }}}),authMode : "API_KEY" })
    if(contacts.data.listContacts.items.length!==0 && checkBox && contacts.data.listContacts.items[0].AuthStatus ==="Invite"){
      let user = contacts.data.listContacts.items[0]
      console.log(user)
        try {
          const  result  = await Auth.signUp({
              username:email,
              password,
              attributes: {
                  email,          // optional
                  phone_number,   // optional - E.164 number convention
                  // other custom attributes 
              },
              autoSignIn: { // optional - enables auto sign in after user is confirmed
                  enabled: true,
              }
          });
          console.log(result);
          let updateContact = await API.graphql({ query: mutations.updateContacts, variables: 
            {input: {_version : user._version ,id:user.id,AuthStatus:"Active", AgreedClickWrap:true, CognitoID:result.user.username,
          Mobile:phone_number}},authMode : "API_KEY"});
          console.log(updateContact)
          window.location.href ="/";
      } catch (error) {
          console.log('error signing up:', error);
          setMessage(error.message)
      }
    }else if (!checkBox && contacts.data.listContacts.items.length!==0 && contacts.data.listContacts.items[0].AuthStatus ==="Invite"){
      let user = contacts.data.listContacts.items[0]
      console.log(user)
      setMessage("You must agree to the Terms of Service");
      let updateContact = await API.graphql({ query: mutations.updateContacts, variables: 
        {input: {_version : user._version ,id:user.id,Mobile:phone_number}},authMode : "API_KEY"});
      console.log(updateContact)
    }else if (contacts.data.listContacts.items.length !== 0){
      setMessage("A user already created");
    }else{
      setMessage("User Could not be created in the portal, please contact the administrators");
    }

}


  return (
    <Flex

      gap="40px"
      direction="column"
      width="100vw"
      height="100vh"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="100px 60px 100px 60px"
      backgroundColor="rgba(229,241,219,1)"
    >
      <Flex
        gap="15px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
      >
        <Heading
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          level="4"
          children="Welcome to your Energy Management Portal (SignUP)"
        ></Heading>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(13,26,38,1)"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Please enter your registered email address, mobile number and password"
          
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          color="red"
          children={[login]}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          color="red"
          children={[message]}
          
        ></Text>
      </Flex>
      <Flex
        gap="21px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <TextField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Email"
          placeholder="Email"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          onChange={(e)=>setEmail(e.target.value)}
          
        ></TextField>
        <TextField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Mobile"
          placeholder="Phone"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          onChange={(e)=>setPhoneNumber(e.target.value)}
          
        ></TextField>
        <PasswordField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Password"
          placeholder="Password"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          onChange={(e)=>setPassword(e.target.value)}
        ></PasswordField>
        <p><input type="checkbox"  onClick={(e)=>{setCheckBox(!checkBox);}} />To create a Google Account, you must agree to the <a href="https://cactiiportal-storage-users71144-staging.s3.ap-southeast-2.amazonaws.com/public/Clickwrap/website-terms-and-conditions-agreement.docx">Terms of Service</a></p>
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Sign In"
          onClick={(e)=>ValidateSignUp()}
        ></Button>
      </Flex>
    </Flex>

  );
}
