import * as React from "react";
import { useEffect,useState  } from 'react';
import { SiteSelect2, SiteSelectionCollection } from "../ui-components";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';



export default function SelectSites(props) {
  const {contacts, overrides, site, ...rest } = props;


  const [sites, setSites] = useState({})
  const [ste, setSte] = useState({})
  const [cte, setCte] = useState({})

  async function updateLastVisitedSite(item) {
    let result = await API.graphql({ query: mutations.updateContacts, variables: {input: {_version : cte._version ,id:contacts.id,LastViewedSite :  item.SiteName, LastViewediD : item.id}}});
    setSte(item);
    setCte(result.data.updateContacts)
  }

  async function getSites() {
    let sites = await API.graphql({...graphqlOperation (queries.listSites, { filter: { sitePrimeUserId : { eq :contacts.id }}}), authMode : "API_KEY" });
    setSites(sites.data.listSites.items);

  }
  
  useEffect(() => {

    if(contacts.id){
      getSites();
      setSte(site);
      setCte(contacts);
      console.log(sites)
    }
    
  },[contacts.id]);
  


  return (
    <SiteSelect2 sites={sites} contacts={contacts}
        overrides ={{"Forinverters":{children : 
        <SiteSelectionCollection items={sites} overrideItems={({ item }) => ({
        onClick: () => updateLastVisitedSite(item)
      })} />},HeaderActive:{contacts : cte}}} />
  );
}
