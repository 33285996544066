/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Contacts, Site } from "../models";
import {
  getOverrideProps,
  useDataStoreUpdateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { schema } from "../models/schema";
import { useEffect } from "react";
import {
  CheckboxField,
  Flex,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import ImportExport2 from "./ImportExport2";
import HeaderActive from "./HeaderActive";
import SideBar2 from "./SideBar2";
export default function Privacy(props) {
  const { contacts, site, default: defaultProp, overrides, ...rest } = props;
  const [
    checkboxFieldFourSixFourEightFourSevenEightThreeChecked,
    setCheckboxFieldFourSixFourEightFourSevenEightThreeChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFourEightFourSevenNineOneChecked,
    setCheckboxFieldFourSixFourEightFourSevenNineOneChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
    setCheckboxFieldFourSixFourEightFourSevenSevenFiveChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFourEightFourSevenSixSevenChecked,
    setCheckboxFieldFourSixFourEightFourSevenSixSevenChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFourEightFourSevenFiveSixChecked,
    setCheckboxFieldFourSixFourEightFourSevenFiveSixChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFiveFourFourOneFiveFiveChecked,
    setCheckboxFieldFourSixFiveFourFourOneFiveFiveChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFiveFourFourOneSevenTwoChecked,
    setCheckboxFieldFourSixFiveFourFourOneSevenTwoChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFiveFiveFourOneThreeEightChecked,
    setCheckboxFieldFourSixFiveFiveFourOneThreeEightChecked,
  ] = useStateMutationAction(false);
  const [
    checkboxFieldFourSixFiveFiveFourOneFourSixChecked,
    setCheckboxFieldFourSixFiveFiveFourOneFourSixChecked,
  ] = useStateMutationAction(false);
  const checkboxFieldFourSixFourEightFourSevenEightThreeOnChange =
    useDataStoreUpdateAction({
      model: Contacts,
      id: contacts?.id,
      fields: {
        Permit_Location:
          checkboxFieldFourSixFourEightFourSevenEightThreeChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFourEightFourSevenNineOneOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        Permit_energyuse: checkboxFieldFourSixFourEightFourSevenNineOneChecked,
        Permit_energygen: checkboxFieldFourSixFourEightFourSevenNineOneChecked,
        Permit_energy_info:
          checkboxFieldFourSixFourEightFourSevenNineOneChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFourEightFourSevenSevenFiveOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: contacts?.id,
      fields: {
        Permit_equip: checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
        Permit_Inverters:
          checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
        Permit_batts: checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
        Permit_ems: checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
        Permit_generation_info:
          checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
        Permit_battery_info:
          checkboxFieldFourSixFourEightFourSevenSevenFiveChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFourEightFourSevenSixSevenOnChange =
    useDataStoreUpdateAction({
      model: Contacts,
      id: contacts?.id,
      fields: {
        Permit_email: checkboxFieldFourSixFourEightFourSevenSixSevenChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFourEightFourSevenFiveSixOnChange =
    useDataStoreUpdateAction({
      model: Contacts,
      id: contacts?.id,
      fields: {
        Permit_Name: checkboxFieldFourSixFourEightFourSevenFiveSixChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFiveFourFourOneFiveFiveOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        Permit_control: checkboxFieldFourSixFiveFourFourOneFiveFiveChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFiveFourFourOneSevenTwoOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        Permit_Sp_control: checkboxFieldFourSixFiveFourFourOneSevenTwoChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFiveFiveFourOneThreeEightOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        Permit_Ai_share: checkboxFieldFourSixFiveFiveFourOneThreeEightChecked,
      },
      schema: schema,
    });
  const checkboxFieldFourSixFiveFiveFourOneFourSixOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        Permit_Ai_control: checkboxFieldFourSixFiveFiveFourOneFourSixChecked,
      },
      schema: schema,
    });
  useEffect(() => {
    if (
      checkboxFieldFourSixFourEightFourSevenEightThreeChecked === false &&
      site !== undefined &&
      site?.Permit_location !== undefined
    )
      setCheckboxFieldFourSixFourEightFourSevenEightThreeChecked(
        site?.Permit_location
      );
  }, [site]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFourEightFourSevenNineOneChecked === false &&
      site !== undefined &&
      site?.Permit_energy_info !== undefined
    )
      setCheckboxFieldFourSixFourEightFourSevenNineOneChecked(
        site?.Permit_energy_info
      );
  }, [site]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFourEightFourSevenSevenFiveChecked === false &&
      contacts !== undefined &&
      contacts?.Permit_equip !== undefined
    )
      setCheckboxFieldFourSixFourEightFourSevenSevenFiveChecked(
        contacts?.Permit_equip
      );
  }, [contacts]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFourEightFourSevenSixSevenChecked === false &&
      contacts !== undefined &&
      contacts?.Permit_email !== undefined
    )
      setCheckboxFieldFourSixFourEightFourSevenSixSevenChecked(
        contacts?.Permit_email
      );
  }, [contacts]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFourEightFourSevenFiveSixChecked === false &&
      contacts !== undefined &&
      contacts?.Permit_Name !== undefined
    )
      setCheckboxFieldFourSixFourEightFourSevenFiveSixChecked(
        contacts?.Permit_Name
      );
  }, [contacts]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFiveFourFourOneFiveFiveChecked === false &&
      site !== undefined &&
      site?.Permit_control !== undefined
    )
      setCheckboxFieldFourSixFiveFourFourOneFiveFiveChecked(
        site?.Permit_control
      );
  }, [site]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFiveFourFourOneSevenTwoChecked === false &&
      site !== undefined &&
      site?.Permit_Sp_control !== undefined
    )
      setCheckboxFieldFourSixFiveFourFourOneSevenTwoChecked(
        site?.Permit_Sp_control
      );
  }, [site]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFiveFiveFourOneThreeEightChecked === false &&
      site !== undefined &&
      site?.Permit_Ai_share !== undefined
    )
      setCheckboxFieldFourSixFiveFiveFourOneThreeEightChecked(
        site?.Permit_Ai_share
      );
  }, [site]);
  useEffect(() => {
    if (
      checkboxFieldFourSixFiveFiveFourOneFourSixChecked === false &&
      site !== undefined &&
      site?.Permit_Ai_control !== undefined
    )
      setCheckboxFieldFourSixFiveFiveFourOneFourSixChecked(
        site?.Permit_Ai_control
      );
  }, [site]);
  return (
    <View
      width="1266px"
      height="800px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(229,241,219,1)"
      {...getOverrideProps(overrides, "Privacy")}
      {...rest}
    >
      <View
        width="1160px"
        height="590px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="150px"
        left="90px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Master Frame")}
      >
        <View
          width="1115px"
          height="404px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="176px"
          left="19px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 492")}
        >
          <CheckboxField
            width="unset"
            height="unset"
            label="Site location - To use correct regulatory settings, determine optimum power and weather."
            position="absolute"
            top="120px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFourEightFourSevenEightThreeChecked}
            onChange={() => {
              checkboxFieldFourSixFourEightFourSevenEightThreeOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFourEightFourSevenEightThreeChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46484783")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Site energy use - To report to you, to take various permitted actions  "
            position="absolute"
            top="90px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFourEightFourSevenNineOneChecked}
            onChange={() => {
              checkboxFieldFourSixFourEightFourSevenNineOneOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFourEightFourSevenNineOneChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46484791")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Site battery/ inverter - To report to you, and to monitor your energy "
            position="absolute"
            top="60px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFourEightFourSevenSevenFiveChecked}
            onChange={() => {
              checkboxFieldFourSixFourEightFourSevenSevenFiveOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFourEightFourSevenSevenFiveChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46484775")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="email address - Used to verify you and notify you of events and trades "
            position="absolute"
            top="30px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFourEightFourSevenSixSevenChecked}
            onChange={() => {
              checkboxFieldFourSixFourEightFourSevenSixSevenOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFourEightFourSevenSixSevenChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46484767")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Your name - Used to address you, and for some compliance issues "
            position="absolute"
            top="0px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFourEightFourSevenFiveSixChecked}
            onChange={() => {
              checkboxFieldFourSixFourEightFourSevenFiveSixOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFourEightFourSevenFiveSixChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46484756")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="The system controls devices within the limits you set? (To optimise your energy use) "
            position="absolute"
            top="175px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFiveFourFourOneFiveFiveChecked}
            onChange={() => {
              checkboxFieldFourSixFiveFourFourOneFiveFiveOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFiveFourFourOneFiveFiveChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46544155")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Can the system access your energy trades and display to you?  "
            position="absolute"
            top="205px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            {...getOverrideProps(overrides, "CheckboxField46544163")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Where you have accepted a Service Providers incentives, can the Provider control your&#xA; devices within the agreed parameters ?"
            position="absolute"
            top="235px"
            left="0px"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFiveFourFourOneSevenTwoChecked}
            onChange={() => {
              checkboxFieldFourSixFiveFourFourOneSevenTwoOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFiveFourFourOneSevenTwoChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46544172")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Can we share your info with an AI ?(We are trialling the use of AI to provide usage advice) "
            position="absolute"
            top="309px"
            left="0px"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFiveFiveFourOneThreeEightChecked}
            onChange={() => {
              checkboxFieldFourSixFiveFiveFourOneThreeEightOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFiveFiveFourOneThreeEightChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46554138")}
          ></CheckboxField>
          <CheckboxField
            width="unset"
            height="unset"
            label="Are you prepared to allow an AI to control and optimise your power use? "
            position="absolute"
            top="339px"
            left="0px"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="end"
            checked={checkboxFieldFourSixFiveFiveFourOneFourSixChecked}
            onChange={() => {
              checkboxFieldFourSixFiveFiveFourOneFourSixOnChange();
            }}
            onChange={(event) => {
              setCheckboxFieldFourSixFiveFiveFourOneFourSixChecked(
                event.target.checked
              );
            }}
            {...getOverrideProps(overrides, "CheckboxField46554146")}
          ></CheckboxField>
        </View>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24.204544067382812px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="980px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="88px"
          left="calc(50% - 490px - 4px)"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="As part of your signup default privacy provisions were accepted allowing the system to use your data. These provisions are explained below, and where feasible you are provided with options to allow you to limit or retract those permissions."
          {...getOverrideProps(
            overrides,
            "As part of your signup default privacy provisions were accepted allowing the system to use your data. These provisions are explained below, and where feasible you are provided with options to allow you to limit or retract those permissions."
          )}
        ></Text>
        <Flex
          gap="22px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="0px"
          left="calc(50% - 243.5px - -0.5px)"
          padding="12px 0px 12px 0px"
          {...getOverrideProps(overrides, "Frame 485")}
        >
          <Text
            fontFamily="Inter"
            fontSize="36px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="43.568180084228516px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Welcome "
            {...getOverrideProps(overrides, "Welcome")}
          ></Text>
          <TextField
            width="300px"
            height="unset"
            shrink="0"
            placeholder="Placeholder"
            size="large"
            isDisabled={true}
            labelHidden={true}
            variation="default"
            label={contacts?.FirstName}
            {...getOverrideProps(overrides, "TextField")}
          ></TextField>
        </Flex>
      </View>
      <Flex
        gap="10px"
        direction="column"
        width="911px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 35px - 284px)"
        left="calc(50% - 455.5px - -52.5px)"
        padding="4px 14px 4px 14px"
        {...getOverrideProps(overrides, "Frame 488")}
      >
        <ImportExport2
          width="905px"
          height="62px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          property1="Default"
          {...getOverrideProps(overrides, "ImportExport2")}
        ></ImportExport2>
      </Flex>
      <HeaderActive
        width="1266px"
        height="70px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(58,86,66,1)"
        {...getOverrideProps(overrides, "HeaderActive")}
      ></HeaderActive>
      <SideBar2
        width="82px"
        height="730px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="70px"
        left="0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(58,86,66,1)"
        {...getOverrideProps(overrides, "SideBar2")}
      ></SideBar2>
    </View>
  );
}
