/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useAuthSignOutAction,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Icon,
  Image,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
export default function HeaderActive(props) {
  const { contacts, overrides, ...rest } = props;
  const buttonOnClick = useAuthSignOutAction({ global: false });
  return (
    <View
      width="1345px"
      height="74px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(58,86,66,1)"
      {...getOverrideProps(overrides, "HeaderActive")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="row"
        width="335px"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        position="absolute"
        top="calc(50% - 23px - -1px)"
        right="0px"
        padding="0px 24px 0px 24px"
        {...getOverrideProps(overrides, "Frame 419")}
      >
        <TextField
          width="197px"
          height="unset"
          placeholder="Name"
          shrink="0"
          size="large"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          value={contacts?.FirstName}
          color="white"
          fontWeight="bold"
          {...getOverrideProps(overrides, "TextField35344662")}
        ></TextField>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Log Out"
          onClick={() => {
            buttonOnClick();
          }}
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="calc(50% - 24.5px - -0.5px)"
        left="calc(50% - 118.5px - -91px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 418")}
      >
        <Image
          width="70px"
          height="49px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://cactiiportal-storage-users71144-staging.s3.ap-southeast-2.amazonaws.com/public/logos/Cactii+symbol+facing+leftclearsmall.png"
          {...getOverrideProps(
            overrides,
            "Cactii symbol facing leftclearsmall 1"
          )}
        ></Image>
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(193,233,199,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Cactii Portal"
          {...getOverrideProps(overrides, "label")}
        ></Text>
      </Flex>
      <View
        width="52px"
        height="39px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="14px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "MyIcon")}
      >
        <Icon
          width="34.67px"
          height="26px"
          viewBox={{ minX: 0, minY: 0, width: 34.666748046875, height: 26 }}
          paths={[
            {
              d: "M0 1.625C0 0.727537 0.97005 0 2.16667 0L32.5 0C33.6966 0 34.6667 0.727537 34.6667 1.625C34.6667 2.52246 33.6966 3.25 32.5 3.25L2.16667 3.25C0.97005 3.25 0 2.52246 0 1.625Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
            {
              d: "M0 13C0 12.1025 0.97005 11.375 2.16667 11.375L32.5 11.375C33.6966 11.375 34.6667 12.1025 34.6667 13C34.6667 13.8975 33.6966 14.625 32.5 14.625L2.16667 14.625C0.97005 14.625 0 13.8975 0 13Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
            {
              d: "M0 24.375C0 23.4775 0.97005 22.75 2.16667 22.75L32.5 22.75C33.6966 22.75 34.6667 23.4775 34.6667 24.375C34.6667 25.2725 33.6966 26 32.5 26L2.16667 26C0.97005 26 0 25.2725 0 24.375Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="16.67%"
          bottom="16.67%"
          left="16.67%"
          right="16.67%"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </View>
      <TextField
        width="300px"
        height="61px"
        placeholder="Site"
        alignItems="center"
        position="absolute"
        top="7px"
        left="206px"
        size="large"
        isDisabled={false}
        labelHidden={true}
        variation="default"
        value={contacts?.LastViewedSite}
        color="white"
        fontWeight="bold"
        {...getOverrideProps(overrides, "TextField35573590")}
      ></TextField>
      <TextField
        width="103px"
        height="52px"
        position="absolute"
        top="13px"
        left="0px"
        placeholder="Placeholder"
        size="small"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        display="none"
        value={contacts?.CognitoID}
        {...getOverrideProps(overrides, "Cognum")}
      ></TextField>
    </View>
  );
}
