/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Card, CheckboxField, Text, View } from "@aws-amplify/ui-react";
export default function SiteSelection(props) {
  const { site, overrides, ...rest } = props;
  const [buttonBackgroundColor, setButtonBackgroundColor] =
    useStateMutationAction("rgba(154,226,161,1)");
  const buttonOnMouseEnter = () => {
    setButtonBackgroundColor("green");
  };
  const buttonOnMouseLeave = () => {
    setButtonBackgroundColor("green/20");
  };
  const checkboxFieldFourSixFiveEightFourOneSixOneOnClick = useNavigateAction({
    type: "reload",
  });
  const checkboxFieldFourSixFiveEightFourOneSixNineOnClick = useNavigateAction({
    type: "reload",
  });
  return (
    <View
      width="319px"
      height="111px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SiteSelection")}
      {...rest}
    >
      <Card
        width="319px"
        height="111px"
        position="absolute"
        backgroundColor="rgba(229,241,219,1)"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        variation="outline"
        {...getOverrideProps(overrides, "Card")}
      ></Card>
      <Text
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="600"
        color="rgba(104,112,120,1)"
        textTransform="uppercase"
        lineHeight="30px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="281px"
        height="34.85px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="0%"
        bottom="68.6%"
        left="5.96%"
        right="5.96%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={site?.SiteName}
        {...getOverrideProps(overrides, "Site Name")}
      ></Text>
      <Button
        width="unset"
        height="unset"
        position="absolute"
        top="calc(50% - 16.5px - -6px)"
        left="39.5%"
        right="39.18%"
        backgroundColor={buttonBackgroundColor}
        size="small"
        isDisabled={true}
        variation="primary"
        children="Select"
        onMouseEnter={() => {
          buttonOnMouseEnter();
        }}
        onMouseLeave={() => {
          buttonOnMouseLeave();
        }}
        {...getOverrideProps(overrides, "Button")}
      ></Button>
      <Text
        fontFamily="Inter"
        fontSize="9px"
        fontWeight="359"
        color="rgba(0,0,0,1)"
        textTransform="capitalize"
        lineHeight="13.5px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="259px"
        height="22px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="20.72%"
        bottom="59.46%"
        left="8.15%"
        right="10.66%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={site?.id}
        {...getOverrideProps(overrides, "siteID")}
      ></Text>
      <CheckboxField
        width="unset"
        height="unset"
        label="Location use  "
        gap="4px"
        position="absolute"
        padding="3px 3px 3px 3px"
        bottom="0px"
        left="0px"
        size="default"
        defaultChecked={true}
        isDisabled={false}
        labelPosition="start"
        checked={site?.Permit_location}
        onClick={() => {
          checkboxFieldFourSixFiveEightFourOneSixOneOnClick();
        }}
        {...getOverrideProps(overrides, "CheckboxField46584161")}
      ></CheckboxField>
      <CheckboxField
        width="unset"
        height="unset"
        label="AI permitted "
        gap="4px"
        position="absolute"
        padding="3px 3px 3px 3px"
        bottom="0px"
        right="0px"
        size="default"
        defaultChecked={false}
        isDisabled={false}
        labelPosition="start"
        checked={site?.Permit_Ai_control}
        onClick={() => {
          checkboxFieldFourSixFiveEightFourOneSixNineOnClick();
        }}
        {...getOverrideProps(overrides, "CheckboxField46584169")}
      ></CheckboxField>
    </View>
  );
}
