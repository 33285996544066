/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIndicator = /* GraphQL */ `
  mutation CreateIndicator(
    $input: CreateIndicatorInput!
    $condition: ModelIndicatorConditionInput
  ) {
    createIndicator(input: $input, condition: $condition) {
      id
      AModel
      Brand
      OS
      OS_version
      WiFi
      Zigbee
      Zwave
      Light
      Other
      ASiteName
      Subscribed
      Status
      Comment
      Indic_serial
      Dev_Name
      DeviceIP
      Dev_Order
      UserNam
      Password
      GateWayIp
      MAC
      Ssid
      Ssid_Pw
      LastUpdate
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Set_Colour
      Reported_Colour
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      indicatorSite_idId
    }
  }
`;
export const updateIndicator = /* GraphQL */ `
  mutation UpdateIndicator(
    $input: UpdateIndicatorInput!
    $condition: ModelIndicatorConditionInput
  ) {
    updateIndicator(input: $input, condition: $condition) {
      id
      AModel
      Brand
      OS
      OS_version
      WiFi
      Zigbee
      Zwave
      Light
      Other
      ASiteName
      Subscribed
      Status
      Comment
      Indic_serial
      Dev_Name
      DeviceIP
      Dev_Order
      UserNam
      Password
      GateWayIp
      MAC
      Ssid
      Ssid_Pw
      LastUpdate
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Set_Colour
      Reported_Colour
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      indicatorSite_idId
    }
  }
`;
export const deleteIndicator = /* GraphQL */ `
  mutation DeleteIndicator(
    $input: DeleteIndicatorInput!
    $condition: ModelIndicatorConditionInput
  ) {
    deleteIndicator(input: $input, condition: $condition) {
      id
      AModel
      Brand
      OS
      OS_version
      WiFi
      Zigbee
      Zwave
      Light
      Other
      ASiteName
      Subscribed
      Status
      Comment
      Indic_serial
      Dev_Name
      DeviceIP
      Dev_Order
      UserNam
      Password
      GateWayIp
      MAC
      Ssid
      Ssid_Pw
      LastUpdate
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Set_Colour
      Reported_Colour
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      indicatorSite_idId
    }
  }
`;
export const createUntitledModel = /* GraphQL */ `
  mutation CreateUntitledModel(
    $input: CreateUntitledModelInput!
    $condition: ModelUntitledModelConditionInput
  ) {
    createUntitledModel(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUntitledModel = /* GraphQL */ `
  mutation UpdateUntitledModel(
    $input: UpdateUntitledModelInput!
    $condition: ModelUntitledModelConditionInput
  ) {
    updateUntitledModel(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUntitledModel = /* GraphQL */ `
  mutation DeleteUntitledModel(
    $input: DeleteUntitledModelInput!
    $condition: ModelUntitledModelConditionInput
  ) {
    deleteUntitledModel(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLoadCtl = /* GraphQL */ `
  mutation CreateLoadCtl(
    $input: CreateLoadCtlInput!
    $condition: ModelLoadCtlConditionInput
  ) {
    createLoadCtl(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      GateWayIp
      OtherIP
      UserNam
      Password
      AModel
      MAC
      OS_Version
      OS
      WiFi
      Eth
      Zigbee
      Zwave
      Brand
      SSiD
      SSiD_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_PowerW
      Last_Contact
      Device_Install
      Status
      Subscribed
      State
      Overide
      Default_Margin
      Current_Setting
      DeviceType
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      loadCtlSite_idId
      owner
    }
  }
`;
export const updateLoadCtl = /* GraphQL */ `
  mutation UpdateLoadCtl(
    $input: UpdateLoadCtlInput!
    $condition: ModelLoadCtlConditionInput
  ) {
    updateLoadCtl(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      GateWayIp
      OtherIP
      UserNam
      Password
      AModel
      MAC
      OS_Version
      OS
      WiFi
      Eth
      Zigbee
      Zwave
      Brand
      SSiD
      SSiD_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_PowerW
      Last_Contact
      Device_Install
      Status
      Subscribed
      State
      Overide
      Default_Margin
      Current_Setting
      DeviceType
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      loadCtlSite_idId
      owner
    }
  }
`;
export const deleteLoadCtl = /* GraphQL */ `
  mutation DeleteLoadCtl(
    $input: DeleteLoadCtlInput!
    $condition: ModelLoadCtlConditionInput
  ) {
    deleteLoadCtl(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      GateWayIp
      OtherIP
      UserNam
      Password
      AModel
      MAC
      OS_Version
      OS
      WiFi
      Eth
      Zigbee
      Zwave
      Brand
      SSiD
      SSiD_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_PowerW
      Last_Contact
      Device_Install
      Status
      Subscribed
      State
      Overide
      Default_Margin
      Current_Setting
      DeviceType
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      loadCtlSite_idId
      owner
    }
  }
`;
export const createAccountItems = /* GraphQL */ `
  mutation CreateAccountItems(
    $input: CreateAccountItemsInput!
    $condition: ModelAccountItemsConditionInput
  ) {
    createAccountItems(input: $input, condition: $condition) {
      id
      ItemDescript
      Item_Num
      GenDiscount
      BasePrice
      ActualPrice
      Cost
      Default_Item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAccountItems = /* GraphQL */ `
  mutation UpdateAccountItems(
    $input: UpdateAccountItemsInput!
    $condition: ModelAccountItemsConditionInput
  ) {
    updateAccountItems(input: $input, condition: $condition) {
      id
      ItemDescript
      Item_Num
      GenDiscount
      BasePrice
      ActualPrice
      Cost
      Default_Item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAccountItems = /* GraphQL */ `
  mutation DeleteAccountItems(
    $input: DeleteAccountItemsInput!
    $condition: ModelAccountItemsConditionInput
  ) {
    deleteAccountItems(input: $input, condition: $condition) {
      id
      ItemDescript
      Item_Num
      GenDiscount
      BasePrice
      ActualPrice
      Cost
      Default_Item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFinancial = /* GraphQL */ `
  mutation CreateFinancial(
    $input: CreateFinancialInput!
    $condition: ModelFinancialConditionInput
  ) {
    createFinancial(input: $input, condition: $condition) {
      id
      Item_num
      ItemDescript
      NumofItems
      Discount
      BasePrice
      ActualPrice
      Cost
      CustomerType
      PayGateway
      PayGateway2
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Tot_Outstanding
      Current
      ASiteNam
      Order
      Bill_Currency
      Subscribed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      financialSite_idId
      owner
    }
  }
`;
export const updateFinancial = /* GraphQL */ `
  mutation UpdateFinancial(
    $input: UpdateFinancialInput!
    $condition: ModelFinancialConditionInput
  ) {
    updateFinancial(input: $input, condition: $condition) {
      id
      Item_num
      ItemDescript
      NumofItems
      Discount
      BasePrice
      ActualPrice
      Cost
      CustomerType
      PayGateway
      PayGateway2
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Tot_Outstanding
      Current
      ASiteNam
      Order
      Bill_Currency
      Subscribed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      financialSite_idId
      owner
    }
  }
`;
export const deleteFinancial = /* GraphQL */ `
  mutation DeleteFinancial(
    $input: DeleteFinancialInput!
    $condition: ModelFinancialConditionInput
  ) {
    deleteFinancial(input: $input, condition: $condition) {
      id
      Item_num
      ItemDescript
      NumofItems
      Discount
      BasePrice
      ActualPrice
      Cost
      CustomerType
      PayGateway
      PayGateway2
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Tot_Outstanding
      Current
      ASiteNam
      Order
      Bill_Currency
      Subscribed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      financialSite_idId
      owner
    }
  }
`;
export const createIoTTypes = /* GraphQL */ `
  mutation CreateIoTTypes(
    $input: CreateIoTTypesInput!
    $condition: ModelIoTTypesConditionInput
  ) {
    createIoTTypes(input: $input, condition: $condition) {
      id
      AModel
      Brand
      Dev_Name
      OS
      OS_version
      WiFi
      Eth
      Zigbee
      Zwave
      RFBridge
      Switch
      Light
      Plug
      Curtain
      Dimmer
      Garage
      Fan
      Heater
      Kettle
      IRController
      Bell
      Valve
      Zigbee_GW
      Sensor_Smoke
      Sensor_Energy
      Rating
      Sensor_Temp
      Sensor_damp
      Sensor_Water_level
      Sensor_Contact
      Sensor_Occupation
      Sensor_Proximity
      Sensor_Gas
      Sensor_Illum
      Sensor_Motion
      Other
      Channels
      Category
      Comments
      IoTandDevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateIoTTypes = /* GraphQL */ `
  mutation UpdateIoTTypes(
    $input: UpdateIoTTypesInput!
    $condition: ModelIoTTypesConditionInput
  ) {
    updateIoTTypes(input: $input, condition: $condition) {
      id
      AModel
      Brand
      Dev_Name
      OS
      OS_version
      WiFi
      Eth
      Zigbee
      Zwave
      RFBridge
      Switch
      Light
      Plug
      Curtain
      Dimmer
      Garage
      Fan
      Heater
      Kettle
      IRController
      Bell
      Valve
      Zigbee_GW
      Sensor_Smoke
      Sensor_Energy
      Rating
      Sensor_Temp
      Sensor_damp
      Sensor_Water_level
      Sensor_Contact
      Sensor_Occupation
      Sensor_Proximity
      Sensor_Gas
      Sensor_Illum
      Sensor_Motion
      Other
      Channels
      Category
      Comments
      IoTandDevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteIoTTypes = /* GraphQL */ `
  mutation DeleteIoTTypes(
    $input: DeleteIoTTypesInput!
    $condition: ModelIoTTypesConditionInput
  ) {
    deleteIoTTypes(input: $input, condition: $condition) {
      id
      AModel
      Brand
      Dev_Name
      OS
      OS_version
      WiFi
      Eth
      Zigbee
      Zwave
      RFBridge
      Switch
      Light
      Plug
      Curtain
      Dimmer
      Garage
      Fan
      Heater
      Kettle
      IRController
      Bell
      Valve
      Zigbee_GW
      Sensor_Smoke
      Sensor_Energy
      Rating
      Sensor_Temp
      Sensor_damp
      Sensor_Water_level
      Sensor_Contact
      Sensor_Occupation
      Sensor_Proximity
      Sensor_Gas
      Sensor_Illum
      Sensor_Motion
      Other
      Channels
      Category
      Comments
      IoTandDevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createImages = /* GraphQL */ `
  mutation CreateImages(
    $input: CreateImagesInput!
    $condition: ModelImagesConditionInput
  ) {
    createImages(input: $input, condition: $condition) {
      id
      cactii
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      ASiteN
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      imagesSite_idId
      owner
    }
  }
`;
export const updateImages = /* GraphQL */ `
  mutation UpdateImages(
    $input: UpdateImagesInput!
    $condition: ModelImagesConditionInput
  ) {
    updateImages(input: $input, condition: $condition) {
      id
      cactii
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      ASiteN
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      imagesSite_idId
      owner
    }
  }
`;
export const deleteImages = /* GraphQL */ `
  mutation DeleteImages(
    $input: DeleteImagesInput!
    $condition: ModelImagesConditionInput
  ) {
    deleteImages(input: $input, condition: $condition) {
      id
      cactii
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      ASiteN
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      imagesSite_idId
      owner
    }
  }
`;
export const createBeSTypes = /* GraphQL */ `
  mutation CreateBeSTypes(
    $input: CreateBeSTypesInput!
    $condition: ModelBeSTypesConditionInput
  ) {
    createBeSTypes(input: $input, condition: $condition) {
      id
      TypeCode
      AModel
      Brand
      Capacity
      Modular
      SinglePh
      ThreePh
      reGenAuth
      ApIExpire
      reGenMasterKey
      reGenUrL
      Comments
      MaxOut
      Hybrid
      BEsSystems {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateBeSTypes = /* GraphQL */ `
  mutation UpdateBeSTypes(
    $input: UpdateBeSTypesInput!
    $condition: ModelBeSTypesConditionInput
  ) {
    updateBeSTypes(input: $input, condition: $condition) {
      id
      TypeCode
      AModel
      Brand
      Capacity
      Modular
      SinglePh
      ThreePh
      reGenAuth
      ApIExpire
      reGenMasterKey
      reGenUrL
      Comments
      MaxOut
      Hybrid
      BEsSystems {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteBeSTypes = /* GraphQL */ `
  mutation DeleteBeSTypes(
    $input: DeleteBeSTypesInput!
    $condition: ModelBeSTypesConditionInput
  ) {
    deleteBeSTypes(input: $input, condition: $condition) {
      id
      TypeCode
      AModel
      Brand
      Capacity
      Modular
      SinglePh
      ThreePh
      reGenAuth
      ApIExpire
      reGenMasterKey
      reGenUrL
      Comments
      MaxOut
      Hybrid
      BEsSystems {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      SiteName
      AFriendlyName
      SiteContact
      SUnit
      SStreet
      SSuburb
      SPostCode
      SState
      SCountry
      SLongitude
      SLatitude
      SElevation
      SPhases
      SRouter
      SRouterComment
      SiteComment
      SiteCommision
      SiteOnPortal
      SiteTrading
      SEnSclupr
      SEnScllwr
      EnergyRetailer
      NmI
      PrimeUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      BillContact {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SiteContct {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SecUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Guest {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      EnergyTot
      EnergyPh1
      EnergyPh2
      EnergyPh3
      SaveVsRes
      ResveTrade
      ClimateMorn
      ClimateMid
      ClimateArvo
      PauseAutomation
      MinPeriodPool
      Include
      UseEvBattery
      EvSoCmin
      Alert1
      AlertTime
      Alert2
      AlertTime2
      OpenSolariD
      Pool_On
      Offline_IoT
      OsStatus
      Indic_MuteStart
      Indic_MuteStop
      Indic_Max_day
      Indic_Max_night
      Indic_Sunrise_pause
      Indic_temp_off
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sitePrimeUserId
      siteBillContactId
      siteSiteContctId
      siteSecUserId
      siteGuestId
      owner
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      SiteName
      AFriendlyName
      SiteContact
      SUnit
      SStreet
      SSuburb
      SPostCode
      SState
      SCountry
      SLongitude
      SLatitude
      SElevation
      SPhases
      SRouter
      SRouterComment
      SiteComment
      SiteCommision
      SiteOnPortal
      SiteTrading
      SEnSclupr
      SEnScllwr
      EnergyRetailer
      NmI
      PrimeUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      BillContact {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SiteContct {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SecUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Guest {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      EnergyTot
      EnergyPh1
      EnergyPh2
      EnergyPh3
      SaveVsRes
      ResveTrade
      ClimateMorn
      ClimateMid
      ClimateArvo
      PauseAutomation
      MinPeriodPool
      Include
      UseEvBattery
      EvSoCmin
      Alert1
      AlertTime
      Alert2
      AlertTime2
      OpenSolariD
      Pool_On
      Offline_IoT
      OsStatus
      Indic_MuteStart
      Indic_MuteStop
      Indic_Max_day
      Indic_Max_night
      Indic_Sunrise_pause
      Indic_temp_off
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sitePrimeUserId
      siteBillContactId
      siteSiteContctId
      siteSecUserId
      siteGuestId
      owner
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      SiteName
      AFriendlyName
      SiteContact
      SUnit
      SStreet
      SSuburb
      SPostCode
      SState
      SCountry
      SLongitude
      SLatitude
      SElevation
      SPhases
      SRouter
      SRouterComment
      SiteComment
      SiteCommision
      SiteOnPortal
      SiteTrading
      SEnSclupr
      SEnScllwr
      EnergyRetailer
      NmI
      PrimeUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      BillContact {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SiteContct {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      SecUser {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Guest {
        id
        AFullName
        LastName
        FirstName
        Unit
        Street
        Suburb
        State
        PostC
        Country
        Mobile
        email
        MobileVerified
        emailVerified
        PrefContactMethod
        AgreedClickWrap
        Gender
        Comments
        currentpassword
        oldpassword
        numSites
        LastViewedSite
        LastViewediD
        Dateviewed
        Site1
        RoleS1
        SiteiD1
        Site2
        RoleS2
        SiteiD2
        Site3
        RoleS3
        SiteiD3
        Site4
        RoleS4
        SiteiD4
        Site5
        RoleS5
        SiteiD5
        AuthStatus
        AuthStatusDate
        AuthStatusComment
        LastAccessDate
        CognitoID
        OsContactId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      EnergyTot
      EnergyPh1
      EnergyPh2
      EnergyPh3
      SaveVsRes
      ResveTrade
      ClimateMorn
      ClimateMid
      ClimateArvo
      PauseAutomation
      MinPeriodPool
      Include
      UseEvBattery
      EvSoCmin
      Alert1
      AlertTime
      Alert2
      AlertTime2
      OpenSolariD
      Pool_On
      Offline_IoT
      OsStatus
      Indic_MuteStart
      Indic_MuteStop
      Indic_Max_day
      Indic_Max_night
      Indic_Sunrise_pause
      Indic_temp_off
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sitePrimeUserId
      siteBillContactId
      siteSiteContctId
      siteSecUserId
      siteGuestId
      owner
    }
  }
`;
export const createEMdevices = /* GraphQL */ `
  mutation CreateEMdevices(
    $input: CreateEMdevicesInput!
    $condition: ModelEMdevicesConditionInput
  ) {
    createEMdevices(input: $input, condition: $condition) {
      id
      SiteName
      AAsiteName
      EmBrand
      EmModel
      EmID
      Read
      Network
      Ch1Label
      Ch2Label
      Ch3Label
      Ch4Label
      Ch5Label
      Ch6Label
      Ch7Label
      Ch8Label
      Antenna
      EmActivation
      EmPhases
      Status
      Comments
      Modbus
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      EmSwitchUsed
      OpenSolarID
      Dev_Order
      SSID
      Subscribed
      State
      AryPosPh1
      AryPosPh2
      AryPosPh3
      AryPosS1Ph1
      AryPosS1Ph2
      AryPosS1Ph3
      AryPosS2Ph1
      AryPosS2Ph2
      AryPosS2Ph3
      ActivationId
      OPENSOLARID
      emdevtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eMdevicesSite_idId
      owner
    }
  }
`;
export const updateEMdevices = /* GraphQL */ `
  mutation UpdateEMdevices(
    $input: UpdateEMdevicesInput!
    $condition: ModelEMdevicesConditionInput
  ) {
    updateEMdevices(input: $input, condition: $condition) {
      id
      SiteName
      AAsiteName
      EmBrand
      EmModel
      EmID
      Read
      Network
      Ch1Label
      Ch2Label
      Ch3Label
      Ch4Label
      Ch5Label
      Ch6Label
      Ch7Label
      Ch8Label
      Antenna
      EmActivation
      EmPhases
      Status
      Comments
      Modbus
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      EmSwitchUsed
      OpenSolarID
      Dev_Order
      SSID
      Subscribed
      State
      AryPosPh1
      AryPosPh2
      AryPosPh3
      AryPosS1Ph1
      AryPosS1Ph2
      AryPosS1Ph3
      AryPosS2Ph1
      AryPosS2Ph2
      AryPosS2Ph3
      ActivationId
      OPENSOLARID
      emdevtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eMdevicesSite_idId
      owner
    }
  }
`;
export const deleteEMdevices = /* GraphQL */ `
  mutation DeleteEMdevices(
    $input: DeleteEMdevicesInput!
    $condition: ModelEMdevicesConditionInput
  ) {
    deleteEMdevices(input: $input, condition: $condition) {
      id
      SiteName
      AAsiteName
      EmBrand
      EmModel
      EmID
      Read
      Network
      Ch1Label
      Ch2Label
      Ch3Label
      Ch4Label
      Ch5Label
      Ch6Label
      Ch7Label
      Ch8Label
      Antenna
      EmActivation
      EmPhases
      Status
      Comments
      Modbus
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      EmSwitchUsed
      OpenSolarID
      Dev_Order
      SSID
      Subscribed
      State
      AryPosPh1
      AryPosPh2
      AryPosPh3
      AryPosS1Ph1
      AryPosS1Ph2
      AryPosS1Ph3
      AryPosS2Ph1
      AryPosS2Ph2
      AryPosS2Ph3
      ActivationId
      OPENSOLARID
      emdevtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eMdevicesSite_idId
      owner
    }
  }
`;
export const createContacts = /* GraphQL */ `
  mutation CreateContacts(
    $input: CreateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    createContacts(input: $input, condition: $condition) {
      id
      AFullName
      LastName
      FirstName
      Unit
      Street
      Suburb
      State
      PostC
      Country
      Mobile
      email
      MobileVerified
      emailVerified
      PrefContactMethod
      AgreedClickWrap
      Gender
      Comments
      currentpassword
      oldpassword
      numSites
      LastViewedSite
      LastViewediD
      Dateviewed
      Site1
      RoleS1
      SiteiD1
      Site2
      RoleS2
      SiteiD2
      Site3
      RoleS3
      SiteiD3
      Site4
      RoleS4
      SiteiD4
      Site5
      RoleS5
      SiteiD5
      AuthStatus
      AuthStatusDate
      AuthStatusComment
      LastAccessDate
      CognitoID
      OsContactId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateContacts = /* GraphQL */ `
  mutation UpdateContacts(
    $input: UpdateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    updateContacts(input: $input, condition: $condition) {
      id
      AFullName
      LastName
      FirstName
      Unit
      Street
      Suburb
      State
      PostC
      Country
      Mobile
      email
      MobileVerified
      emailVerified
      PrefContactMethod
      AgreedClickWrap
      Gender
      Comments
      currentpassword
      oldpassword
      numSites
      LastViewedSite
      LastViewediD
      Dateviewed
      Site1
      RoleS1
      SiteiD1
      Site2
      RoleS2
      SiteiD2
      Site3
      RoleS3
      SiteiD3
      Site4
      RoleS4
      SiteiD4
      Site5
      RoleS5
      SiteiD5
      AuthStatus
      AuthStatusDate
      AuthStatusComment
      LastAccessDate
      CognitoID
      OsContactId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteContacts = /* GraphQL */ `
  mutation DeleteContacts(
    $input: DeleteContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    deleteContacts(input: $input, condition: $condition) {
      id
      AFullName
      LastName
      FirstName
      Unit
      Street
      Suburb
      State
      PostC
      Country
      Mobile
      email
      MobileVerified
      emailVerified
      PrefContactMethod
      AgreedClickWrap
      Gender
      Comments
      currentpassword
      oldpassword
      numSites
      LastViewedSite
      LastViewediD
      Dateviewed
      Site1
      RoleS1
      SiteiD1
      Site2
      RoleS2
      SiteiD2
      Site3
      RoleS3
      SiteiD3
      Site4
      RoleS4
      SiteiD4
      Site5
      RoleS5
      SiteiD5
      AuthStatus
      AuthStatusDate
      AuthStatusComment
      LastAccessDate
      CognitoID
      OsContactId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createEmDevtypes = /* GraphQL */ `
  mutation CreateEmDevtypes(
    $input: CreateEmDevtypesInput!
    $condition: ModelEmDevtypesConditionInput
  ) {
    createEmDevtypes(input: $input, condition: $condition) {
      id
      Model
      AShortModel
      EmBrand
      Channels
      Modbus
      Switch
      reGenAuth
      reGenKey
      Commsmeth
      reGenUrl
      Comments
      EMdevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateEmDevtypes = /* GraphQL */ `
  mutation UpdateEmDevtypes(
    $input: UpdateEmDevtypesInput!
    $condition: ModelEmDevtypesConditionInput
  ) {
    updateEmDevtypes(input: $input, condition: $condition) {
      id
      Model
      AShortModel
      EmBrand
      Channels
      Modbus
      Switch
      reGenAuth
      reGenKey
      Commsmeth
      reGenUrl
      Comments
      EMdevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteEmDevtypes = /* GraphQL */ `
  mutation DeleteEmDevtypes(
    $input: DeleteEmDevtypesInput!
    $condition: ModelEmDevtypesConditionInput
  ) {
    deleteEmDevtypes(input: $input, condition: $condition) {
      id
      Model
      AShortModel
      EmBrand
      Channels
      Modbus
      Switch
      reGenAuth
      reGenKey
      Commsmeth
      reGenUrl
      Comments
      EMdevices {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createEnosiSetting = /* GraphQL */ `
  mutation CreateEnosiSetting(
    $input: CreateEnosiSettingInput!
    $condition: ModelEnosiSettingConditionInput
  ) {
    createEnosiSetting(input: $input, condition: $condition) {
      id
      ACustGivenName
      TCustEmail
      TCustFamilyname
      TPropName
      ImpAvePrice
      ImpTradedvalue
      ImpTradedenergy
      ExpAvePrice
      ExpTradedvalue
      ExpTradedenergy
      TPeriodstart
      TPeriodEnd
      TMeter1Num
      TMeter2Num
      TMeter3Num
      TNomSell1
      TNomSell2
      TNomSell3
      TNomBuy1
      TNomBuy2
      TNomBuy3
      TResidSell1
      TResidSell2
      TResidBuy1
      TResidBuy2
      TID1
      TTimestampNow
      TID2
      TTimestampLast
      TRuleIBuy1
      TBuyPt1
      TRuleIBuy2
      TBuyPt2
      TRuleBuy3
      TBuyPt3
      TRuleSell1
      TSellPt1
      TRuleSell2
      TSellPt2
      TRuleSell3
      TSellPt3
      WarningMessageNum
      NewTradeMessages
      ASiteName
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      enosiSettingSite_idId
      owner
    }
  }
`;
export const updateEnosiSetting = /* GraphQL */ `
  mutation UpdateEnosiSetting(
    $input: UpdateEnosiSettingInput!
    $condition: ModelEnosiSettingConditionInput
  ) {
    updateEnosiSetting(input: $input, condition: $condition) {
      id
      ACustGivenName
      TCustEmail
      TCustFamilyname
      TPropName
      ImpAvePrice
      ImpTradedvalue
      ImpTradedenergy
      ExpAvePrice
      ExpTradedvalue
      ExpTradedenergy
      TPeriodstart
      TPeriodEnd
      TMeter1Num
      TMeter2Num
      TMeter3Num
      TNomSell1
      TNomSell2
      TNomSell3
      TNomBuy1
      TNomBuy2
      TNomBuy3
      TResidSell1
      TResidSell2
      TResidBuy1
      TResidBuy2
      TID1
      TTimestampNow
      TID2
      TTimestampLast
      TRuleIBuy1
      TBuyPt1
      TRuleIBuy2
      TBuyPt2
      TRuleBuy3
      TBuyPt3
      TRuleSell1
      TSellPt1
      TRuleSell2
      TSellPt2
      TRuleSell3
      TSellPt3
      WarningMessageNum
      NewTradeMessages
      ASiteName
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      enosiSettingSite_idId
      owner
    }
  }
`;
export const deleteEnosiSetting = /* GraphQL */ `
  mutation DeleteEnosiSetting(
    $input: DeleteEnosiSettingInput!
    $condition: ModelEnosiSettingConditionInput
  ) {
    deleteEnosiSetting(input: $input, condition: $condition) {
      id
      ACustGivenName
      TCustEmail
      TCustFamilyname
      TPropName
      ImpAvePrice
      ImpTradedvalue
      ImpTradedenergy
      ExpAvePrice
      ExpTradedvalue
      ExpTradedenergy
      TPeriodstart
      TPeriodEnd
      TMeter1Num
      TMeter2Num
      TMeter3Num
      TNomSell1
      TNomSell2
      TNomSell3
      TNomBuy1
      TNomBuy2
      TNomBuy3
      TResidSell1
      TResidSell2
      TResidBuy1
      TResidBuy2
      TID1
      TTimestampNow
      TID2
      TTimestampLast
      TRuleIBuy1
      TBuyPt1
      TRuleIBuy2
      TBuyPt2
      TRuleBuy3
      TBuyPt3
      TRuleSell1
      TSellPt1
      TRuleSell2
      TSellPt2
      TRuleSell3
      TSellPt3
      WarningMessageNum
      NewTradeMessages
      ASiteName
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      enosiSettingSite_idId
      owner
    }
  }
`;
export const createEvChargers = /* GraphQL */ `
  mutation CreateEvChargers(
    $input: CreateEvChargersInput!
    $condition: ModelEvChargersConditionInput
  ) {
    createEvChargers(input: $input, condition: $condition) {
      id
      ASiteName
      EvChBrand
      EvChModel
      EvChCapacity
      EvChApiaddr
      EvChApiKey
      EvChSerialNum
      EvChPhases
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      BoostUntilSoC
      PriotisedSoC
      Only_Solar
      Dev_Order
      Overide
      Status
      State
      Subscribed
      Comments
      Device_Install
      Ssid
      Temperature
      DeviceIP
      DeviceName
      Last_Voltage
      Last_PowerW
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      evChargersSite_idId
      owner
    }
  }
`;
export const updateEvChargers = /* GraphQL */ `
  mutation UpdateEvChargers(
    $input: UpdateEvChargersInput!
    $condition: ModelEvChargersConditionInput
  ) {
    updateEvChargers(input: $input, condition: $condition) {
      id
      ASiteName
      EvChBrand
      EvChModel
      EvChCapacity
      EvChApiaddr
      EvChApiKey
      EvChSerialNum
      EvChPhases
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      BoostUntilSoC
      PriotisedSoC
      Only_Solar
      Dev_Order
      Overide
      Status
      State
      Subscribed
      Comments
      Device_Install
      Ssid
      Temperature
      DeviceIP
      DeviceName
      Last_Voltage
      Last_PowerW
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      evChargersSite_idId
      owner
    }
  }
`;
export const deleteEvChargers = /* GraphQL */ `
  mutation DeleteEvChargers(
    $input: DeleteEvChargersInput!
    $condition: ModelEvChargersConditionInput
  ) {
    deleteEvChargers(input: $input, condition: $condition) {
      id
      ASiteName
      EvChBrand
      EvChModel
      EvChCapacity
      EvChApiaddr
      EvChApiKey
      EvChSerialNum
      EvChPhases
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      BoostUntilSoC
      PriotisedSoC
      Only_Solar
      Dev_Order
      Overide
      Status
      State
      Subscribed
      Comments
      Device_Install
      Ssid
      Temperature
      DeviceIP
      DeviceName
      Last_Voltage
      Last_PowerW
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      evChargersSite_idId
      owner
    }
  }
`;
export const createWattWatchperAPI = /* GraphQL */ `
  mutation CreateWattWatchperAPI(
    $input: CreateWattWatchperAPIInput!
    $condition: ModelWattWatchperAPIConditionInput
  ) {
    createWattWatchperAPI(input: $input, condition: $condition) {
      id
      AASite
      WWID
      APIKey
      APIAddress
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateWattWatchperAPI = /* GraphQL */ `
  mutation UpdateWattWatchperAPI(
    $input: UpdateWattWatchperAPIInput!
    $condition: ModelWattWatchperAPIConditionInput
  ) {
    updateWattWatchperAPI(input: $input, condition: $condition) {
      id
      AASite
      WWID
      APIKey
      APIAddress
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteWattWatchperAPI = /* GraphQL */ `
  mutation DeleteWattWatchperAPI(
    $input: DeleteWattWatchperAPIInput!
    $condition: ModelWattWatchperAPIConditionInput
  ) {
    deleteWattWatchperAPI(input: $input, condition: $condition) {
      id
      AASite
      WWID
      APIKey
      APIAddress
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createEnergyNow = /* GraphQL */ `
  mutation CreateEnergyNow(
    $input: CreateEnergyNowInput!
    $condition: ModelEnergyNowConditionInput
  ) {
    createEnergyNow(input: $input, condition: $condition) {
      id
      ASiteName
      Timestamp
      Frequency
      SolarAry
      GridAry
      StorageAry
      OtherAryy
      HWSAry
      IndicatorAry
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      energyNowSite_idId
      owner
    }
  }
`;
export const updateEnergyNow = /* GraphQL */ `
  mutation UpdateEnergyNow(
    $input: UpdateEnergyNowInput!
    $condition: ModelEnergyNowConditionInput
  ) {
    updateEnergyNow(input: $input, condition: $condition) {
      id
      ASiteName
      Timestamp
      Frequency
      SolarAry
      GridAry
      StorageAry
      OtherAryy
      HWSAry
      IndicatorAry
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      energyNowSite_idId
      owner
    }
  }
`;
export const deleteEnergyNow = /* GraphQL */ `
  mutation DeleteEnergyNow(
    $input: DeleteEnergyNowInput!
    $condition: ModelEnergyNowConditionInput
  ) {
    deleteEnergyNow(input: $input, condition: $condition) {
      id
      ASiteName
      Timestamp
      Frequency
      SolarAry
      GridAry
      StorageAry
      OtherAryy
      HWSAry
      IndicatorAry
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      energyNowSite_idId
      owner
    }
  }
`;
export const createOnsiteProcessor = /* GraphQL */ `
  mutation CreateOnsiteProcessor(
    $input: CreateOnsiteProcessorInput!
    $condition: ModelOnsiteProcessorConditionInput
  ) {
    createOnsiteProcessor(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceName
      GreenGrassName
      HostName
      GateWayIP
      LocalIP
      ZeroTierIp
      MAC
      OS
      Processor
      UserName
      UPassword
      Ssid
      Ssid_Password
      POE
      LastUpdate
      Temperature
      Other
      LastContact
      DeviceInstall
      Comments
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Subscribed
      Status
      State
      Tx_Ssid
      Tx_Ssid_Pw
      Portal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      onsiteProcessorSite_idId
      owner
    }
  }
`;
export const updateOnsiteProcessor = /* GraphQL */ `
  mutation UpdateOnsiteProcessor(
    $input: UpdateOnsiteProcessorInput!
    $condition: ModelOnsiteProcessorConditionInput
  ) {
    updateOnsiteProcessor(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceName
      GreenGrassName
      HostName
      GateWayIP
      LocalIP
      ZeroTierIp
      MAC
      OS
      Processor
      UserName
      UPassword
      Ssid
      Ssid_Password
      POE
      LastUpdate
      Temperature
      Other
      LastContact
      DeviceInstall
      Comments
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Subscribed
      Status
      State
      Tx_Ssid
      Tx_Ssid_Pw
      Portal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      onsiteProcessorSite_idId
      owner
    }
  }
`;
export const deleteOnsiteProcessor = /* GraphQL */ `
  mutation DeleteOnsiteProcessor(
    $input: DeleteOnsiteProcessorInput!
    $condition: ModelOnsiteProcessorConditionInput
  ) {
    deleteOnsiteProcessor(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceName
      GreenGrassName
      HostName
      GateWayIP
      LocalIP
      ZeroTierIp
      MAC
      OS
      Processor
      UserName
      UPassword
      Ssid
      Ssid_Password
      POE
      LastUpdate
      Temperature
      Other
      LastContact
      DeviceInstall
      Comments
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Subscribed
      Status
      State
      Tx_Ssid
      Tx_Ssid_Pw
      Portal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      onsiteProcessorSite_idId
      owner
    }
  }
`;
export const createSiteSolarInverter = /* GraphQL */ `
  mutation CreateSiteSolarInverter(
    $input: CreateSiteSolarInverterInput!
    $condition: ModelSiteSolarInverterConditionInput
  ) {
    createSiteSolarInverter(input: $input, condition: $condition) {
      id
      ASiteName
      SolarModel
      SolarBrand
      SolarSerialNum
      RegNum
      SolarPhasesCtd
      SolarP1
      SolarP2
      SolarP3
      APIAddress
      SolarAPIKey
      EPSCtd
      SiteSolarDeviceNum
      SitePanelPeakCtd
      SolarCommsNet
      Dongle
      Hybrid
      CommDate
      SiteName
      Comments
      Dev_Order
      Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      PowerNow
      Subscribed
      State
      solarinvertertypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      siteSolarInverterSite_idId
      owner
    }
  }
`;
export const updateSiteSolarInverter = /* GraphQL */ `
  mutation UpdateSiteSolarInverter(
    $input: UpdateSiteSolarInverterInput!
    $condition: ModelSiteSolarInverterConditionInput
  ) {
    updateSiteSolarInverter(input: $input, condition: $condition) {
      id
      ASiteName
      SolarModel
      SolarBrand
      SolarSerialNum
      RegNum
      SolarPhasesCtd
      SolarP1
      SolarP2
      SolarP3
      APIAddress
      SolarAPIKey
      EPSCtd
      SiteSolarDeviceNum
      SitePanelPeakCtd
      SolarCommsNet
      Dongle
      Hybrid
      CommDate
      SiteName
      Comments
      Dev_Order
      Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      PowerNow
      Subscribed
      State
      solarinvertertypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      siteSolarInverterSite_idId
      owner
    }
  }
`;
export const deleteSiteSolarInverter = /* GraphQL */ `
  mutation DeleteSiteSolarInverter(
    $input: DeleteSiteSolarInverterInput!
    $condition: ModelSiteSolarInverterConditionInput
  ) {
    deleteSiteSolarInverter(input: $input, condition: $condition) {
      id
      ASiteName
      SolarModel
      SolarBrand
      SolarSerialNum
      RegNum
      SolarPhasesCtd
      SolarP1
      SolarP2
      SolarP3
      APIAddress
      SolarAPIKey
      EPSCtd
      SiteSolarDeviceNum
      SitePanelPeakCtd
      SolarCommsNet
      Dongle
      Hybrid
      CommDate
      SiteName
      Comments
      Dev_Order
      Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      PowerNow
      Subscribed
      State
      solarinvertertypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      siteSolarInverterSite_idId
      owner
    }
  }
`;
export const createIoTandDevices = /* GraphQL */ `
  mutation CreateIoTandDevices(
    $input: CreateIoTandDevicesInput!
    $condition: ModelIoTandDevicesConditionInput
  ) {
    createIoTandDevices(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      HostName
      GateWayIp
      OtherIP
      Mac
      UserNam
      Password
      Ssid
      Ssid_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_current
      Last_PowerW
      Last_VaR
      Last_Image
      Last_Other1
      Last_Other2
      Last_Contact
      Device_Install
      Status
      Comments
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Used_Where
      Overide
      State
      DeviceType
      OS
      Subscribed
      Processor
      Tx_Ssid
      Set_Colour
      Reported_Colour
      iottypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ioTandDevicesSite_idId
      owner
    }
  }
`;
export const updateIoTandDevices = /* GraphQL */ `
  mutation UpdateIoTandDevices(
    $input: UpdateIoTandDevicesInput!
    $condition: ModelIoTandDevicesConditionInput
  ) {
    updateIoTandDevices(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      HostName
      GateWayIp
      OtherIP
      Mac
      UserNam
      Password
      Ssid
      Ssid_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_current
      Last_PowerW
      Last_VaR
      Last_Image
      Last_Other1
      Last_Other2
      Last_Contact
      Device_Install
      Status
      Comments
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Used_Where
      Overide
      State
      DeviceType
      OS
      Subscribed
      Processor
      Tx_Ssid
      Set_Colour
      Reported_Colour
      iottypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ioTandDevicesSite_idId
      owner
    }
  }
`;
export const deleteIoTandDevices = /* GraphQL */ `
  mutation DeleteIoTandDevices(
    $input: DeleteIoTandDevicesInput!
    $condition: ModelIoTandDevicesConditionInput
  ) {
    deleteIoTandDevices(input: $input, condition: $condition) {
      id
      ASiteName
      DeviceIP
      DeviceNetwork
      Dev_Order
      DeviceName
      HostName
      GateWayIp
      OtherIP
      Mac
      UserNam
      Password
      Ssid
      Ssid_Pw
      PoE
      LastUpdate
      Temperature
      Last_Voltage
      Last_current
      Last_PowerW
      Last_VaR
      Last_Image
      Last_Other1
      Last_Other2
      Last_Contact
      Device_Install
      Status
      Comments
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Used_Where
      Overide
      State
      DeviceType
      OS
      Subscribed
      Processor
      Tx_Ssid
      Set_Colour
      Reported_Colour
      iottypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ioTandDevicesSite_idId
      owner
    }
  }
`;
export const createBEsSystems = /* GraphQL */ `
  mutation CreateBEsSystems(
    $input: CreateBEsSystemsInput!
    $condition: ModelBEsSystemsConditionInput
  ) {
    createBEsSystems(input: $input, condition: $condition) {
      id
      ASiteName
      EsBrand
      EsModel
      EsType
      EsApiAddr
      EsApiKey
      EsPower
      EsSerialNumber
      EsStateOfCharge
      EsPhases
      ESCapacity
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      State
      Status
      Trade_off_Battery
      Subscribed
      bestypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bEsSystemsSite_idId
      owner
    }
  }
`;
export const updateBEsSystems = /* GraphQL */ `
  mutation UpdateBEsSystems(
    $input: UpdateBEsSystemsInput!
    $condition: ModelBEsSystemsConditionInput
  ) {
    updateBEsSystems(input: $input, condition: $condition) {
      id
      ASiteName
      EsBrand
      EsModel
      EsType
      EsApiAddr
      EsApiKey
      EsPower
      EsSerialNumber
      EsStateOfCharge
      EsPhases
      ESCapacity
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      State
      Status
      Trade_off_Battery
      Subscribed
      bestypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bEsSystemsSite_idId
      owner
    }
  }
`;
export const deleteBEsSystems = /* GraphQL */ `
  mutation DeleteBEsSystems(
    $input: DeleteBEsSystemsInput!
    $condition: ModelBEsSystemsConditionInput
  ) {
    deleteBEsSystems(input: $input, condition: $condition) {
      id
      ASiteName
      EsBrand
      EsModel
      EsType
      EsApiAddr
      EsApiKey
      EsPower
      EsSerialNumber
      EsStateOfCharge
      EsPhases
      ESCapacity
      Dev_Order
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      State
      Status
      Trade_off_Battery
      Subscribed
      bestypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bEsSystemsSite_idId
      owner
    }
  }
`;
export const createSolarInverterTypes = /* GraphQL */ `
  mutation CreateSolarInverterTypes(
    $input: CreateSolarInverterTypesInput!
    $condition: ModelSolarInverterTypesConditionInput
  ) {
    createSolarInverterTypes(input: $input, condition: $condition) {
      id
      TypeCode
      AModel
      Phases
      PeakPower
      Hybrid
      RSolarInv {
        nextToken
        startedAt
      }
      Brand
      ApiAddress
      ApiKey
      reGenAuth
      reGenMaster
      reGenURL
      ApIKeyexpire
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateSolarInverterTypes = /* GraphQL */ `
  mutation UpdateSolarInverterTypes(
    $input: UpdateSolarInverterTypesInput!
    $condition: ModelSolarInverterTypesConditionInput
  ) {
    updateSolarInverterTypes(input: $input, condition: $condition) {
      id
      TypeCode
      AModel
      Phases
      PeakPower
      Hybrid
      RSolarInv {
        nextToken
        startedAt
      }
      Brand
      ApiAddress
      ApiKey
      reGenAuth
      reGenMaster
      reGenURL
      ApIKeyexpire
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteSolarInverterTypes = /* GraphQL */ `
  mutation DeleteSolarInverterTypes(
    $input: DeleteSolarInverterTypesInput!
    $condition: ModelSolarInverterTypesConditionInput
  ) {
    deleteSolarInverterTypes(input: $input, condition: $condition) {
      id
      TypeCode
      AModel
      Phases
      PeakPower
      Hybrid
      RSolarInv {
        nextToken
        startedAt
      }
      Brand
      ApiAddress
      ApiKey
      reGenAuth
      reGenMaster
      reGenURL
      ApIKeyexpire
      Comments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createLifXTypes = /* GraphQL */ `
  mutation CreateLifXTypes(
    $input: CreateLifXTypesInput!
    $condition: ModelLifXTypesConditionInput
  ) {
    createLifXTypes(input: $input, condition: $condition) {
      id
      ModelNum
      AModelNum
      Fitting
      Lumens
      Format
      Type
      Comments
      ColourOptions
      LiFxes {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateLifXTypes = /* GraphQL */ `
  mutation UpdateLifXTypes(
    $input: UpdateLifXTypesInput!
    $condition: ModelLifXTypesConditionInput
  ) {
    updateLifXTypes(input: $input, condition: $condition) {
      id
      ModelNum
      AModelNum
      Fitting
      Lumens
      Format
      Type
      Comments
      ColourOptions
      LiFxes {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteLifXTypes = /* GraphQL */ `
  mutation DeleteLifXTypes(
    $input: DeleteLifXTypesInput!
    $condition: ModelLifXTypesConditionInput
  ) {
    deleteLifXTypes(input: $input, condition: $condition) {
      id
      ModelNum
      AModelNum
      Fitting
      Lumens
      Format
      Type
      Comments
      ColourOptions
      LiFxes {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createOtherDevices = /* GraphQL */ `
  mutation CreateOtherDevices(
    $input: CreateOtherDevicesInput!
    $condition: ModelOtherDevicesConditionInput
  ) {
    createOtherDevices(input: $input, condition: $condition) {
      id
      DeviceType
      ASiteName
      SerialNum
      Device_name
      Device_Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      Desription
      Comments
      Overide
      State
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      otherDevicesSite_idId
      owner
    }
  }
`;
export const updateOtherDevices = /* GraphQL */ `
  mutation UpdateOtherDevices(
    $input: UpdateOtherDevicesInput!
    $condition: ModelOtherDevicesConditionInput
  ) {
    updateOtherDevices(input: $input, condition: $condition) {
      id
      DeviceType
      ASiteName
      SerialNum
      Device_name
      Device_Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      Desription
      Comments
      Overide
      State
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      otherDevicesSite_idId
      owner
    }
  }
`;
export const deleteOtherDevices = /* GraphQL */ `
  mutation DeleteOtherDevices(
    $input: DeleteOtherDevicesInput!
    $condition: ModelOtherDevicesConditionInput
  ) {
    deleteOtherDevices(input: $input, condition: $condition) {
      id
      DeviceType
      ASiteName
      SerialNum
      Device_name
      Device_Status
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Dev_Order
      Desription
      Comments
      Overide
      State
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      otherDevicesSite_idId
      owner
    }
  }
`;
export const createLiFx = /* GraphQL */ `
  mutation CreateLiFx(
    $input: CreateLiFxInput!
    $condition: ModelLiFxConditionInput
  ) {
    createLiFx(input: $input, condition: $condition) {
      id
      ALifxID
      LifXType
      AaSiteName
      Primary
      MuteStart
      MuteStop
      Max_Day_bright
      Max_Night_bright
      LifXApIKey
      ApIaddress
      LifXColour
      ColourPh1
      ColourPh2
      ColourPh3
      Dev_Order
      SunrisePause
      Subscribed
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Status
      lifxtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liFxSite_idId
      owner
    }
  }
`;
export const updateLiFx = /* GraphQL */ `
  mutation UpdateLiFx(
    $input: UpdateLiFxInput!
    $condition: ModelLiFxConditionInput
  ) {
    updateLiFx(input: $input, condition: $condition) {
      id
      ALifxID
      LifXType
      AaSiteName
      Primary
      MuteStart
      MuteStop
      Max_Day_bright
      Max_Night_bright
      LifXApIKey
      ApIaddress
      LifXColour
      ColourPh1
      ColourPh2
      ColourPh3
      Dev_Order
      SunrisePause
      Subscribed
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Status
      lifxtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liFxSite_idId
      owner
    }
  }
`;
export const deleteLiFx = /* GraphQL */ `
  mutation DeleteLiFx(
    $input: DeleteLiFxInput!
    $condition: ModelLiFxConditionInput
  ) {
    deleteLiFx(input: $input, condition: $condition) {
      id
      ALifxID
      LifXType
      AaSiteName
      Primary
      MuteStart
      MuteStop
      Max_Day_bright
      Max_Night_bright
      LifXApIKey
      ApIaddress
      LifXColour
      ColourPh1
      ColourPh2
      ColourPh3
      Dev_Order
      SunrisePause
      Subscribed
      Site_id {
        id
        SiteName
        AFriendlyName
        SiteContact
        SUnit
        SStreet
        SSuburb
        SPostCode
        SState
        SCountry
        SLongitude
        SLatitude
        SElevation
        SPhases
        SRouter
        SRouterComment
        SiteComment
        SiteCommision
        SiteOnPortal
        SiteTrading
        SEnSclupr
        SEnScllwr
        EnergyRetailer
        NmI
        EnergyTot
        EnergyPh1
        EnergyPh2
        EnergyPh3
        SaveVsRes
        ResveTrade
        ClimateMorn
        ClimateMid
        ClimateArvo
        PauseAutomation
        MinPeriodPool
        Include
        UseEvBattery
        EvSoCmin
        Alert1
        AlertTime
        Alert2
        AlertTime2
        OpenSolariD
        Pool_On
        Offline_IoT
        OsStatus
        Indic_MuteStart
        Indic_MuteStop
        Indic_Max_day
        Indic_Max_night
        Indic_Sunrise_pause
        Indic_temp_off
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sitePrimeUserId
        siteBillContactId
        siteSiteContctId
        siteSecUserId
        siteGuestId
        owner
      }
      Status
      lifxtypesID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liFxSite_idId
      owner
    }
  }
`;
