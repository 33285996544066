import * as React from "react";
import { useEffect,useState  } from 'react';
import { HomeFrame, InverterstatusCollection, IOTDevicestatusCollection } from "../ui-components";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import SelectionOptionStartMute from "./SelectionOptionStartMute";
import SelectionOptionStopMute from "./SelectionOptionStopMute";



export default function Home(props) {
  const {contacts, overrides, site, ...rest } = props;


  const [liFx, setLiFx] = useState({id:""})
  const [inverters, setInverters] = useState({id:""})
  const [iotAndDevices, setIotAndDevices] = useState({id:""})

  async function getLifx() {
        if (site.SiteName){
          let liFxes = await API.graphql({...graphqlOperation(queries.listLiFxes, {filter: { AaSiteName: { eq :site.SiteName }}}), authMode : "API_KEY" })
          if (liFxes.data.listLiFxes.items[0]) {
            setLiFx(liFxes.data.listLiFxes.items[0]);
          }
        }
  }

  async function getInverters(){
    if (site.SiteName){
      let inverters = await API.graphql({...graphqlOperation(queries.listSiteSolarInverters , {filter: { ASiteName: { eq :site.SiteName }}}), authMode : "API_KEY" })
          if (inverters.data.listSiteSolarInverters.items[0]) {
            setInverters(inverters.data.listSiteSolarInverters.items)
          }
    }
  }

  async function getIotAndDevices(){
    if (site.SiteName){
      let iots= await API.graphql({...graphqlOperation(queries.listIoTandDevices , {filter: { ASiteName: { eq :site.SiteName }}}), authMode : "API_KEY" })
          if (iots.data.listIoTandDevices.items[0]) {
            setIotAndDevices(iots.data.listIoTandDevices.items)
          }
    }
  }

  async function updateStartMute(e){
    let result = await API.graphql({ query: mutations.updateLiFx, variables: {input: {_version : liFx._version, id:liFx.id, MuteStart:e.value}}});
    setLiFx(result.data.updateLiFx);
  }

  async function updateStopMute(e){
    let result = await API.graphql({ query: mutations.updateLiFx, variables: {input: {_version : liFx._version, id:liFx.id, MuteStop:e.value}}});
    setLiFx(result.data.updateLiFx);
  }
  
  useEffect(() => {
  
    getLifx()
    getInverters()
    getIotAndDevices()
    console.log(site)
    console.log(contacts)
    console.log(liFx)
    
  },[site?.id,contacts?.id,liFx.id, site.id, inverters?.id,iotAndDevices?.id]);
  


  return (
    <HomeFrame liFx={liFx} site={site} contacts={contacts} 
    inverterCollection={<InverterstatusCollection items={inverters} />}
    iOTCollection={<IOTDevicestatusCollection items={iotAndDevices} />}
    overrides ={{
      HeaderActive:{contacts : contacts, site : site  },
      ImportExport2:{contacts : contacts, site : site  },
      //TextField35273073: {children: <SelectionOptionStartMute start={liFx.MuteStart}/>, onChange : (e)=>{updateStartMute(e.target)}},
      //TextField35273081: {children: <SelectionOptionStopMute stop={liFx.MuteStop} />, onChange : (e)=>{updateStopMute(e.target)}},
    }} />
  );
}
