/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Site } from "../models";
import {
  getOverrideProps,
  useDataStoreUpdateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { schema } from "../models/schema";
import { useEffect } from "react";
import {
  Badge,
  Flex,
  Heading,
  Radio,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import HeaderActive from "./HeaderActive";
import SideBar2 from "./SideBar2";
import ImportExport2 from "./ImportExport2";
export default function HomeFrame(props) {
  const {
    liFx,
    site,
    bEsSystems,
    frame489,
    storage,
    iOTCollection,
    inverterCollection,
    storageCollection,
    display,
    siteSolarInverter,
    OnLine,
    Offpeak,
    OffPeakCollection,
    ioTTypes,
    overrides,
    ...rest
  } = props;
  const [
    textFieldThreeFiveTwoSevenThreeZeroSevenThreeValue,
    setTextFieldThreeFiveTwoSevenThreeZeroSevenThreeValue,
  ] = useStateMutationAction("");
  const [
    textFieldThreeFiveTwoSevenThreeZeroEightOneValue,
    setTextFieldThreeFiveTwoSevenThreeZeroEightOneValue,
  ] = useStateMutationAction("");
  const [radioColor, setRadioColor] = useStateMutationAction(undefined);
  const textFieldThreeFiveTwoSevenThreeZeroSevenThreeOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        MuteStart: textFieldThreeFiveTwoSevenThreeZeroSevenThreeValue,
        Indic_MuteStart: textFieldThreeFiveTwoSevenThreeZeroSevenThreeValue,
      },
      schema: schema,
    });
  const textFieldThreeFiveTwoSevenThreeZeroEightOneOnChange =
    useDataStoreUpdateAction({
      model: Site,
      id: site?.id,
      fields: {
        MuteStop: textFieldThreeFiveTwoSevenThreeZeroEightOneValue,
        Indic_MuteStop: textFieldThreeFiveTwoSevenThreeZeroEightOneValue,
      },
      schema: schema,
    });
  const radioOnMouseMove = () => {
    setRadioColor("green");
  };
  useEffect(() => {
    if (
      textFieldThreeFiveTwoSevenThreeZeroSevenThreeValue === "" &&
      site !== undefined &&
      site?.Indic_MuteStart !== undefined
    )
      setTextFieldThreeFiveTwoSevenThreeZeroSevenThreeValue(
        site?.Indic_MuteStart
      );
  }, [site]);
  useEffect(() => {
    if (
      textFieldThreeFiveTwoSevenThreeZeroEightOneValue === "" &&
      site !== undefined &&
      site?.Indic_MuteStop !== undefined
    )
      setTextFieldThreeFiveTwoSevenThreeZeroEightOneValue(site?.Indic_MuteStop);
  }, [site]);
  return (
    <View
      width="100vw"
      height="1052px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(229,241,219,1)"
      {...getOverrideProps(overrides, "HomeFrame")}
      {...rest}
    >
      <Flex
        gap="12px"
        direction="column"
        width="1096px"
        height="748px"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="calc(50% - 374px - -138px)"
        left="calc(50% - 548px - -52.5px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Devices")}
      >
        <Heading
          width="unset"
          height="unset"
          shrink="0"
          level="3"
          fontWeight="bold"
          children="Devices"
          {...getOverrideProps(overrides, "Heading34983113")}
        ></Heading>
        <View
          width="unset"
          height="175px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Inverters")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="1076px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="29px"
            left="0px"
            padding="10px 10px 10px 10px"
            overflow="auto"
            children={inverterCollection}
            {...getOverrideProps(overrides, "InverterCollection")}
          ></Flex>
          <Heading
            width="unset"
            height="unset"
            position="absolute"
            top="-0.5px"
            left="503px"
            level="4"
            children="Inverters"
            {...getOverrideProps(overrides, "Heading37584073")}
          ></Heading>
        </View>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="209px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Storage")}
        >
          <Heading
            width="unset"
            height="unset"
            shrink="0"
            level="4"
            children="Storage"
            {...getOverrideProps(overrides, "Heading37584067")}
          ></Heading>
          <Flex
            gap="10px"
            direction="row"
            width="1084px"
            height="176px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            overflow="auto"
            children={storageCollection}
            {...getOverrideProps(overrides, "StorageCollection")}
          ></Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="1090px"
          height="171px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "LoadCtl")}
        >
          <Heading
            width="unset"
            height="unset"
            shrink="0"
            level="4"
            children="Fractional Load Devices"
            {...getOverrideProps(overrides, "Heading37564057")}
          ></Heading>
          <Flex
            gap="10px"
            direction="row"
            width="1080px"
            height="122px"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 0px"
            children=""
            {...getOverrideProps(overrides, "LoadCtlCollection")}
          ></Flex>
        </Flex>
        <View
          width="1086px"
          height="115px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IOTSummary")}
        >
          <TextField
            width="300px"
            height="unset"
            placeholder="Expected Number Devices"
            position="absolute"
            top="45px"
            left="361px"
            size="large"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            label="Expected Number"
            {...getOverrideProps(overrides, "TextField39553854")}
          ></TextField>
          <Badge
            width="unset"
            height="unset"
            position="absolute"
            top="45px"
            left="695px"
            size="large"
            variation="warning"
            colour={site?.Offline_IOT == "0" ? "sucess" : "warning"}
            extreme={site?.Offline_IOT >= "5" ? "error" : "warning"}
            children={site?.Offline_IOT}
            {...getOverrideProps(overrides, "Badge")}
          ></Badge>
          <Heading
            width="unset"
            height="unset"
            position="absolute"
            top="-0.5px"
            left="435px"
            level="4"
            children="Summary IOT Status"
            {...getOverrideProps(overrides, "Heading39553851")}
          ></Heading>
        </View>
      </Flex>
      <Flex
        gap="22px"
        direction="row"
        width="1132px"
        height="116px"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="calc(50% - 58px - 292px)"
        left="calc(50% - 566px - -74.5px)"
        padding="9px 0px 9px 0px"
        {...getOverrideProps(overrides, "IndicLight")}
      >
        <Heading
          width="211px"
          height="unset"
          padding="0px 12px 0px 12px"
          shrink="0"
          level="3"
          fontWeight="bold"
          children="Indicator light"
          {...getOverrideProps(overrides, "Heading34932900")}
        ></Heading>
        <TextField
          width="209px"
          height="unset"
          label="Start mute (time)"
          placeholder="Start Mute"
          shrink="0"
          size="large"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          value={textFieldThreeFiveTwoSevenThreeZeroSevenThreeValue}
          onChange={() => {
            textFieldThreeFiveTwoSevenThreeZeroSevenThreeOnChange();
          }}
          onChange={(event) => {
            setTextFieldThreeFiveTwoSevenThreeZeroSevenThreeValue(
              event.target.value
            );
          }}
          {...getOverrideProps(overrides, "TextField35273073")}
        ></TextField>
        <TextField
          width="214px"
          height="unset"
          label="Stop mute (time)"
          placeholder="Stop Mute"
          shrink="0"
          size="large"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          value={textFieldThreeFiveTwoSevenThreeZeroEightOneValue}
          onChange={() => {
            textFieldThreeFiveTwoSevenThreeZeroEightOneOnChange();
          }}
          onChange={(event) => {
            setTextFieldThreeFiveTwoSevenThreeZeroEightOneValue(
              event.target.value
            );
          }}
          {...getOverrideProps(overrides, "TextField35273081")}
        ></TextField>
        <Radio
          width="154px"
          height="30px"
          justifyContent="flex-end"
          padding="0px 20px 0px 20px"
          shrink="0"
          size="Large"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="start"
          children="Temp mute"
          color={radioColor}
          onMouseMove={() => {
            radioOnMouseMove();
          }}
          {...getOverrideProps(overrides, "Radio")}
        ></Radio>
      </Flex>
      <HeaderActive
        width="100vw"
        height="77px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(58,86,66,1)"
        {...getOverrideProps(overrides, "HeaderActive")}
      ></HeaderActive>
      <SideBar2
        width="77px"
        height="975px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="77px"
        left="0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(58,86,66,1)"
        {...getOverrideProps(overrides, "SideBar2")}
      ></SideBar2>
      <Flex
        gap="10px"
        direction="column"
        width="855px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 35px - 390.5px)"
        left="calc(50% - 427.5px - -39px)"
        padding="4px 14px 4px 14px"
        {...getOverrideProps(overrides, "Frame 488")}
      >
        <ImportExport2
          width="924px"
          height="62px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          property1="Default"
          {...getOverrideProps(overrides, "ImportExport2")}
        ></ImportExport2>
      </Flex>
    </View>
  );
}
