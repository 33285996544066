import * as React from "react";
import { useEffect,useState  } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  Button,
  Flex,
  Heading,
  PasswordField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";


export default function CustomSignIn() {

  const [message, setMessage] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [username, setUsername] = useState();
  const [code, setCode] = useState();
  const [signupForm, setSignupForm] = useState("SIGNIN");
  const [phone_number, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [checkBox, setCheckBox] = useState(false);
  //const signup = (<a href="/signup">Click here to Register</a>);

  async function ValidateSignIn() {

    try {
      const user = await Auth.signIn(username, password);
      window.location.href ="/HomeFrame";
    } catch (error) {
      if (error.message.includes("User does not exist") || error.message.includes("Custom auth lambda trigger is not configured for the user pool")){
        console.log("checking user existance : "+username)
        let contacts = await API.graphql({...graphqlOperation(queries.listContacts, { filter:{ or: [ {email: { eq :username }}, { Mobile: { eq :username }}]}}),authMode : "API_KEY" })
          if (contacts.data.listContacts.items.length!==0 && (contacts.data.listContacts.items[0].AuthStatus ==="Invited" || contacts.data.listContacts.items[0].AuthStatus ==="Invite")){
            let contact = contacts.data.listContacts.items[0];
            setContact(contact);
              setSignupForm("SIGNUP");
              if(username.includes('@')){
                setEmail(username);
                setPhoneNumber(contact.Mobile)
              }  else {
                setPhoneNumber(username)
                setEmail(contact.email)
              }
          }else{
            console.log('error signing in', error);
            setMessage(error.message);
          }
        }else{
          if(error.message.includes("confirm")){
            setSignupForm("CONFIRM");
          }else{
            console.log('error signing in', error);
            setMessage(error.message);
          }
          
        }
        
    }

  }



  async function ValidateSignUp() {
    setMessage("");
    let contacts = await API.graphql({...graphqlOperation(queries.listContacts, { filter: { email: { eq :email }}}),authMode : "API_KEY" })
    if(contact.id && checkBox && (contact.AuthStatus ==="Invited" || contact.AuthStatus ==="Invite" ) && password==confirmPassword){
      let user = contact
      console.log(user)
        try {
          const  result  = await Auth.signUp({
              username:email,
              password,
              attributes: {
                  email,          // optional
                  phone_number,   // optional - E.164 number convention
                  'custom:OSCONTACTID':contact.OsContactId,
                  // other custom attributes 
              },
              autoSignIn: { // optional - enables auto sign in after user is confirmed
                  enabled: true,
              }
          });
          console.log(result);
          let updateContact = await API.graphql({ query: mutations.updateContacts, variables: 
            {input: {_version : user._version ,id:user.id,AuthStatus:"Active", AgreedClickWrap:true, CognitoID:result.userSub,
          Mobile:phone_number}},authMode : "API_KEY"});
          console.log(updateContact)
          setSignupForm("CONFIRM");
          //window.location.href ="/";
      } catch (error) {
          console.log('error signing up:', error);
          setMessage(error.message)
      }
    }else if(password != confirmPassword){
      setMessage("Passwords are not matching");
    }else if (!checkBox && contact.id && (contact.AuthStatus ==="Invited" || contact.AuthStatus ==="Invite") ){
      let user = contact
      console.log(user)
      setMessage("You must agree to the Terms of Service");
      let updateContact = await API.graphql({ query: mutations.updateContacts, variables: 
        {input: {_version : user._version ,id:user.id,Mobile:phone_number}},authMode : "API_KEY"});
      console.log(updateContact)
    }else if (contacts.data.listContacts.items.length !== 0){
      setMessage("A user already created");
    }else{
      setMessage("User Could not be created in the portal, please contact us at portalsupport@cactii.com.au");
    }

}

async function resendConfirmationCode() {
  try {
      await Auth.resendSignUp(username);
      console.log('code resent successfully');
  } catch (err) {
      console.log('error resending code: ', err);
  }
}

async function confirmSignUp() {
  try {
    await Auth.confirmSignUp(username, code);
    window.location.href ="/";
  } catch (error) {
      console.log('error confirming sign up', error);

  }
}


async function forgotPassword(){
  setSignupForm("FORGOT");
}


async function recoverPasswordUp(){
  Auth.forgotPasswordSubmit(username, code, password)
  .then((data) => {
    console.log(data)
    setMessage("Password Reseted Please reconnect !!!")
  })
  .catch((err) =>{
    console.log(err)
    setMessage(err.message)
  });
}

async function resendForgotCode(){
  console.log(username);
  Auth.forgotPassword(username)
  .then((data) => {
    console.log(data);
    setSignupForm("CONFIRMFORGOT");
    setMessage("A confirmation Code was sent via "+ data.CodeDeliveryDetails.DeliveryMedium+" to the "+data.CodeDeliveryDetails.Destination+" destination.");
})
  .catch((err) =>{
    console.log(err)
    setMessage(err.message)
  });
}


if(signupForm ==="SIGNIN")
  return (
    <Flex

      gap="40px"
      direction="column"
      width="100vw"
      height="100vh"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="100px 60px 100px 60px"
      backgroundColor="rgba(229,241,219,1)"
    >
      <Flex
        gap="15px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
      >
        <Heading
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          level="4"
          children="Welcome to your Energy Management Portal (SignIn)"
        ></Heading>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(13,26,38,1)"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Please enter your registered email address or mobile number and password"
          
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          color="red"
          children={[message]}
          
        ></Text>
      </Flex>
      <Flex
        gap="21px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <TextField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Email or Phone"
          placeholder="Placeholder"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          onChange={(e)=>{setUsername(e.target.value);}}
          
        ></TextField>
        <PasswordField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Password"
          placeholder="Placeholder"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          onChange={(e)=>setPassword(e.target.value)}
        ></PasswordField>
        <a href="#" onClick={(e)=>forgotPassword()}>Forgot password ?</a>
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Sign In"
          onClick={(e)=>ValidateSignIn()}
        ></Button>
      </Flex>
    </Flex>

  );
else if (signupForm ==="SIGNUP")  return (
  <Flex

      gap="40px"
      direction="column"
      width="100vw"
      height="100vh"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="100px 60px 100px 60px"
      backgroundColor="rgba(229,241,219,1)"
    >
      <Flex
        gap="15px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
      >
        <Heading
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          level="4"
          children="Welcome to your Energy Management Portal"
        ></Heading>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(13,26,38,1)"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Seems to be your first connection. Please confirm and complete your registered email address and mobile number"
          
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          color="red"
          children={[message]}
          
        ></Text>
      </Flex>
      <Flex
        gap="21px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <TextField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Email"
          placeholder="Email"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          
        ></TextField>
        <TextField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Mobile"
          placeholder="Phone"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          value={phone_number}
          onChange={(e)=>setPhoneNumber(e.target.value)}
          
        ></TextField>
        <PasswordField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Your Password"
          placeholder="Create your password"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          onChange={(e)=>setPassword(e.target.value)}
        ></PasswordField>
        <PasswordField
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Confirm Password"
          placeholder="Confirm the password"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          onChange={(e)=>setConfirmPassword(e.target.value)}
        ></PasswordField>
        <p><input type="checkbox"  onClick={(e)=>{setCheckBox(!checkBox);}} />To create your Cactii Portal Account, you must accept the "Licence to use Portal Agreement" by "Ticking" your acceptance<a href="https://cactiiportal-storage-users05308-prod.s3.ap-southeast-2.amazonaws.com/public/Clickwrap/Portal-terms-and-conditions.docx">Portal Licence</a></p>
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Validate"
          onClick={(e)=>ValidateSignUp()}
        ></Button>
      </Flex>
    </Flex>
)
else if (signupForm ==="CONFIRM") return (
<Flex

gap="40px"
direction="column"
width="100vw"
height="100vh"
justifyContent="flex-start"
alignItems="center"
position="relative"
padding="100px 60px 100px 60px"
backgroundColor="rgba(229,241,219,1)"
>
<Flex
  gap="15px"
  direction="column"
  width="unset"
  height="unset"
  justifyContent="flex-start"
  alignItems="center"
  shrink="0"
  position="relative"
  padding="0px 0px 0px 0px"
>
  <Heading
    display="flex"
    gap="0"
    direction="row"
    width="unset"
    height="unset"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
    level="4"
    children="Welcome to your Energy Management Portal (Account Confirmation)"
  ></Heading>
  <Text
    fontFamily="Inter"
    fontSize="16px"
    fontWeight="400"
    color="rgba(13,26,38,1)"
    lineHeight="16px"
    textAlign="center"
    display="block"
    direction="column"
    justifyContent="unset"
    width="unset"
    height="unset"
    gap="unset"
    alignItems="unset"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
    whiteSpace="pre-wrap"
    children="A confirmation code has been sent please enter it to confirm your account"
    
  ></Text>
  <Text
    fontFamily="Inter"
    fontSize="16px"
    fontWeight="400"
    lineHeight="16px"
    textAlign="center"
    display="block"
    direction="column"
    justifyContent="unset"
    width="unset"
    height="unset"
    gap="unset"
    alignItems="unset"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
    whiteSpace="pre-wrap"
    color="red"
    children={[message]}
    
  ></Text>
</Flex>
<Flex
  gap="21px"
  direction="column"
  width="unset"
  height="unset"
  justifyContent="flex-start"
  alignItems="center"
  shrink="0"
  position="relative"
  padding="0px 0px 0px 0px"
  
>
  <TextField
    display="flex"
    direction="column"
    width="250px"
    height="unset"
    alignItems="flex-start"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
    label="Enter Confirmation code below"
    placeholder="Code"
    size="small"
    isDisabled={false}
    labelHidden={false}
    variation="default"
    onChange={(e)=>setCode(e.target.value)}
    
  ></TextField>
  <Button
    display="flex"
    gap="0"
    direction="row"
    width="unset"
    height="unset"
    justifyContent="center"
    alignItems="center"
    shrink="0"
    alignSelf="stretch"
    position="relative"
    border="1px SOLID rgba(0,0,0,1)"
    size="small"
    isDisabled={false}
    variation="primary"
    children="Validate"
    onClick={(e)=>confirmSignUp()}
  ></Button>
  <Button
    display="flex"
    gap="0"
    direction="row"
    width="unset"
    height="unset"
    justifyContent="center"
    alignItems="center"
    shrink="0"
    alignSelf="stretch"
    position="relative"
    border="1px SOLID rgba(0,0,0,1)"
    size="small"
    isDisabled={false}
    variation="primary"
    children="Resend confirmation code"
    onClick={(e)=>resendConfirmationCode()}
  ></Button>
</Flex>
</Flex>
)

else if (signupForm ==="FORGOT")  return (<Flex

  gap="40px"
  direction="column"
  width="100vw"
  height="100vh"
  justifyContent="flex-start"
  alignItems="center"
  position="relative"
  padding="100px 60px 100px 60px"
  backgroundColor="rgba(229,241,219,1)"
  >
  <Flex
    gap="15px"
    direction="column"
    width="unset"
    height="unset"
    justifyContent="flex-start"
    alignItems="center"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
  >
    <Heading
      display="flex"
      gap="0"
      direction="row"
      width="unset"
      height="unset"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      level="4"
      children="You forgot your password ?"
    ></Heading>
    <Text
      fontFamily="Inter"
      fontSize="16px"
      fontWeight="400"
      color="rgba(13,26,38,1)"
      lineHeight="16px"
      textAlign="center"
      display="block"
      direction="column"
      justifyContent="unset"
      width="unset"
      height="unset"
      gap="unset"
      alignItems="unset"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      whiteSpace="pre-wrap"
      children="Enter your username to send a confirmation code"
      
    ></Text>
    <Text
      fontFamily="Inter"
      fontSize="16px"
      fontWeight="400"
      lineHeight="16px"
      textAlign="center"
      display="block"
      direction="column"
      justifyContent="unset"
      width="unset"
      height="unset"
      gap="unset"
      alignItems="unset"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      whiteSpace="pre-wrap"
      color="red"
      children={[message]}
      
    ></Text>
  </Flex>
  <Flex
    gap="21px"
    direction="column"
    width="unset"
    height="unset"
    justifyContent="flex-start"
    alignItems="center"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
    
  >
    <TextField
      display="flex"
      direction="column"
      width="250px"
      height="unset"
      alignItems="flex-start"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      label="Username"
      placeholder="username"
      size="small"
      isDisabled={false}
      labelHidden={false}
      variation="default"
      onChange={(e)=>setUsername(e.target.value)}
      
    ></TextField>
    <Button
      display="flex"
      gap="0"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      shrink="0"
      alignSelf="stretch"
      position="relative"
      border="1px SOLID rgba(0,0,0,1)"
      size="small"
      isDisabled={false}
      variation="primary"
      children="Send code"
      onClick={(e)=>resendForgotCode()}
    ></Button>
  </Flex>
  </Flex>)

else if (signupForm ==="CONFIRMFORGOT")  return (<Flex

  gap="40px"
  direction="column"
  width="100vw"
  height="100vh"
  justifyContent="flex-start"
  alignItems="center"
  position="relative"
  padding="100px 60px 100px 60px"
  backgroundColor="rgba(229,241,219,1)"
  >
  <Flex
    gap="15px"
    direction="column"
    width="unset"
    height="unset"
    justifyContent="flex-start"
    alignItems="center"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
  >
    <Heading
      display="flex"
      gap="0"
      direction="row"
      width="unset"
      height="unset"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      level="4"
      children="Welcome to your Energy Management Portal (Account Confirmation)"
    ></Heading>
    <Text
      fontFamily="Inter"
      fontSize="16px"
      fontWeight="400"
      color="rgba(13,26,38,1)"
      lineHeight="16px"
      textAlign="center"
      display="block"
      direction="column"
      justifyContent="unset"
      width="unset"
      height="unset"
      gap="unset"
      alignItems="unset"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      whiteSpace="pre-wrap"
      children="A confirmation code has been sent please enter it to confirm your account"
      
    ></Text>
    <Text
      fontFamily="Inter"
      fontSize="16px"
      fontWeight="400"
      lineHeight="16px"
      textAlign="center"
      display="block"
      direction="column"
      justifyContent="unset"
      width="unset"
      height="unset"
      gap="unset"
      alignItems="unset"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      whiteSpace="pre-wrap"
      color="red"
      children={[message]}
      
    ></Text>
  </Flex>
  <Flex
    gap="21px"
    direction="column"
    width="unset"
    height="unset"
    justifyContent="flex-start"
    alignItems="center"
    shrink="0"
    position="relative"
    padding="0px 0px 0px 0px"
    
  >
    <PasswordField
          label="New Password"
          placeholder="New Password"
          display="flex"
          direction="column"
          width="250px"
          height="unset"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hideShowPassword={false}
          onChange={(e)=>setPassword(e.target.value)}
        ></PasswordField>
    <TextField
      display="flex"
      direction="column"
      width="250px"
      height="unset"
      alignItems="flex-start"
      shrink="0"
      position="relative"
      padding="0px 0px 0px 0px"
      label="Confirmation Code"
      placeholder="Code"
      size="small"
      isDisabled={false}
      labelHidden={false}
      variation="default"
      onChange={(e)=>setCode(e.target.value)}
      
    ></TextField>
    <Button
      display="flex"
      gap="0"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      shrink="0"
      alignSelf="stretch"
      position="relative"
      border="1px SOLID rgba(0,0,0,1)"
      size="small"
      isDisabled={false}
      variation="primary"
      children="Validate"
      onClick={(e)=>recoverPasswordUp()}
    ></Button>
    <Button
      display="flex"
      gap="0"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      shrink="0"
      alignSelf="stretch"
      position="relative"
      border="1px SOLID rgba(0,0,0,1)"
      size="small"
      isDisabled={false}
      variation="primary"
      children="Resend code"
      onClick={(e)=>resendForgotCode()}
    ></Button>
  </Flex>
  </Flex>)

else return(<></>)
;
}
