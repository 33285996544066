import * as React from "react";
import { useEffect,useState  } from 'react';
import { Changepassword } from "../ui-components";
import { Auth } from 'aws-amplify';


export default function ChangePassword(props) {
  const {contacts, overrides, site, ...rest } = props;


  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [newConfirmPassword, setNewConfirmPassword] = useState()
  const [message, setMessage] = useState()
  const [color, setColor] = useState('red')

  async function changePassword() {
    if(newPassword === newConfirmPassword){
      Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);  
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(
        (data) =>{
        setMessage("Password updated successfully");
        console.log(message);
        setColor('green')
        setCurrentPassword('');
        setNewConfirmPassword('');
        setNewPassword('');
      
      })
      .catch((err) => {
        console.log(err.message);
        setMessage(err.message);
        setColor('red')
      });
    }else{
      setMessage("Passwords doesn't match...!!!");
      setColor('red');
    }

  }

  
  useEffect(() => {
  
    
  },[]);
  


  return (
    <Changepassword    overrides ={{"newPasswordField" :{onChange : (e)=>setNewPassword(e.target.value)}, 
    "oldPasswordField" :{onChange : (e)=>setCurrentPassword(e.target.value)},
    "passwordField" :{onChange : (e)=>setNewConfirmPassword(e.target.value)},
    "Button" :{onClick : ()=>changePassword()},
    "Error" : {children : message, color : color}, }}/>
  );
}
